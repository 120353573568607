<template>
    <div>
        <div class="rebus-img" v-if="gameType === 'rebus'">
          <img v-if="imgSRC"  @load="rebusLoad" :src="getImageUrl(imgSRC)" alt="">
        </div>

        <div class="riddle-bg riddle-bg-imaginary" v-if="gameType === 'pic2'">
          <img v-for="(imgItem, index) in imgArray" :key="imgItem" :src="getImageUrl(imgItem)" @load="pic2LoadImg()" :class="['imaginary-img']" alt="" @click="openInfoPopUp(index)">
          <img v-if="imgArray.length && imgArray.length<3" src="@/assets/img/locked-pic.jpg" :class="['imaginary-img', {'imaginary-img-lock' : true}]" alt=""> 
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/store/user';
import { useMainStore } from '@/store';
import { mapState, mapActions } from 'pinia';


export default {
    name: 'imgField',
    props: {
        gameType: {
            tips: String,
            default: '',
        },
        imgSRC: {
            tips: String,
            default: '',
        },
        imgArray: {
            tips: Array,
            default: [],
        },
        preloadImgArray: {
            tips: Array,
            default: [],
        },
    },
    computed: {
        ...mapState(useMainStore, ['darkTheme']),
        ...mapState(useUserStore, ['staticPrefix']),
    },
    methods: {
        getImageUrl(url) {
            return new URL(url, this.staticPrefix).href
        },
        rebusLoad() {
            this.$emit('rebusLoad');
        },
        pic2LoadImg() {
            this.$emit('pic2LoadImg');
        },
        openInfoPopUp(index) {
            this.$emit('openInfoPopUp', index);
        },
    },
}

</script>
