import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import  ShopView from '../views/ShopView.vue'
import  ErrorView from '../views/ErrorView.vue'
import  LangsView from '../views/LangsView.vue'
import  winnersView from '../views/winnersView.vue'
import  pageView from '../views/pageView.vue'
import  RebusView from '../views/RebusView.vue'
import  RebusGameView from '../views/RebusGameView.vue'
import  MemorinaGameView from '../views/MemorinaGameView.vue'
import  PuzzleView from '../views/PuzzleView.vue'
import  ProfileView from '../views/ProfileView.vue'
import  ColorsGameView from '../views/ColorsGameView.vue'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)',
      name: 'error404Full',
      component: ErrorView,
      meta: { errorPage: true },
    },
    {
      path: '/:#error404',
      name: 'error404',
      component: ErrorView,
      meta: { errorPage: true },
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        transition: 'fade',
        title: 'Home Page - Example App',
        metaTags: [
          {
            name: 'description',
            content: 'The home page of our example app.'
          },
          {
            property: 'og:description',
            content: 'The home page of our example app.'
          }
        ],
      },
    },
    {
      path: '/#langs',
      name: 'langs',
      component: LangsView,
      meta: { transition: 'fade' },
    },
    {
      path: '/#shop',
      name: 'shop',
      component: ShopView,
      meta: { transition: 'fade' },
      beforeEnter: (to, from, next) => {
       if (from.meta.gameType) {
        to.meta.game = from.fullPath;
       }
        next();
      }
    },
    {
      path: '/#profile',
      name: 'profile',
      component: ProfileView,
      meta: { transition: 'fade' },
    },
    {
      path: '/#winners',
      name: 'winners',
      component: winnersView,
      meta: { transition: 'fade', hideNavigation: true, hideHeader: true},
    },
    {
      path: '/#legal',
      name: 'legal',
      component: pageView,
      meta: { transition: 'fade', hideNavigation: true, hideHeader: true},
    },
    {
      path: '/#rebus',
      name: 'rebus',
      component: RebusView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'rebus' },
    },
    {
      path: '/#rebusgame',
      name: 'rebusgame',
      component: RebusGameView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'rebus' },
    },
    {
      path: '/#pic2',
      name: 'pic2',
      component: RebusView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'pic2' },
    },
    {
      path: '/#pic2game',
      name: 'pic2game',
      component: RebusGameView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'pic2' },
    },
    {
      path: '/#memorina',
      name: 'memorina',
      component: RebusView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'memorina' },
    },
    {
      path: '/#memorinagame',
      name: 'memorinagame',
      component: MemorinaGameView,
      meta: { transition: 'fade', hideNavigation: true, scrollWrap: true, gameType: 'memorina' },
    },
    {
      path: '/#colors',
      name: 'colors',
      component: RebusView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'colors' },
    },
    {
      path: '/#colorsgame',
      name: 'colorsgame',
      component: ColorsGameView,
      meta: { transition: 'fade', hideNavigation: true, gameType: 'colors' },
    },
    {
      path: '/#puzzle',
      name: 'puzzle',
      component: PuzzleView,
      meta: { transition: 'fade', hideNavigation: true},
    }
  ],
})


router.beforeEach((to, from, next) => {
    if (to.hash) {
      const listRoute = router.getRoutes(to);
      const newName = to.hash.substring(1);
      let newRoute = listRoute.find(el => el.name === to.hash.substring(1));
      newRoute ? next({name: to.hash.substring(1), replace: true}) : next({name: 'error404', replace: true});
    } else {
      next();
    }
  })


export default router
