import { createApp } from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { createI18n } from "vue-i18n";
import { createVfm } from 'vue-final-modal'
import VueClipboard from 'vue3-clipboard'

import * as Sentry from "@sentry/vue";

import router from './router'
import App from './App.vue'
import 'vue-final-modal/style.css'
import './assets/styles/main.scss'

import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import ru from "./locales/ru.json"
import en from "./locales/en.json"
import uz from "./locales/uz.json"

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const accessToken = urlParams.get('accessToken')
if (accessToken) {
	window.location = '/check-token'+queryString
}

let userLang = navigator.language || navigator.userLanguage;
userLang = userLang.slice(0, userLang.length)

const i18n = createI18n({
	legacy: false,
	globalInjection: true,
	locale: 'ru',
	// locale: userLang? userLang : 'ru',
	fallbackLocale: "en",
	availableLocales: ["en", "ru", "uz"],
	messages: {
		ru,
		en,
		uz,
	},
	pluralizationRules: {
		'ru': function(choice, choicesLength) {

			if (choice === 0) {
				return 0;
			}

			const teen = choice > 10 && choice < 20;
			const endsWithOne = choice % 10 === 1;

			if (choicesLength < 4) {
				return (!teen && endsWithOne) ? 1 : 2;
			}
			if (!teen && endsWithOne) {
				return 1;
			}
			if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
				return 2;
			}

			return (choicesLength < 4) ? 2 : 3;
		}
	}
});

const app = createApp(App)
const vfm = createVfm()

if (process.env.VUE_APP_SENTRY) {
	Sentry.init({
		app,
		dsn: process.env.VUE_APP_SENTRY,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
			new Sentry.Replay(),
		],
		tracesSampleRate: 0.3,
		tracePropagationTargets: ["localhost", process.env.VUE_APP_API_URL],
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		denyUrls: [
			/mc\.yandex\.ru/i,
			/mc\.yandex\.com/i,
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i,
		],
	});
}

app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(vfm)
app.use(VueClipboard, {
	autoSetContainer: true,
	appendToBody: true,
})
app.use(Vue3Toasity, {
	autoClose: 2000,
	hideProgressBar: true
})

app.mount('#app')
