import { defineStore } from 'pinia';
import Http from '@/Http';
import { useUserStore } from './user';

const authUrl = process.env.VUE_APP_AUTH_URL;
const appUrl = process.env.VUE_APP_URL;
const tokenKey = process.env.VUE_APP_SESSION_COOKIE;

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuth: false,
    token: null,
  }),
  actions: {
    async login() {
      const token = decodeURI(Cookies.get(tokenKey))
      if (token && token !=='undefined') {
        this.isAuth = true;
        this.token = token;
        await this.loadData();
        return true;
      }
      return false;
    },
    async logout() {
      this.isAuth = false;
      this.token = null;
      window.location.href = (`/logout`);
    },
    async setToken(token) {
      this.isAuth = true;
      localStorage.setItem(tokenKey, token);
      this.token = token;
      const result = await Http.get('/sanctum/csrf-cookie');
      if (result) {
        return true;
      }
      return false;
    },
    async loadData() {
      const promises = [
        useUserStore().getUserInfo(),
      ];
      await Promise.allSettled(promises);
      return true;
    },
  },
});
