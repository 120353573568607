<template>
  <div class="page-container">
    <div class="page-title">Пазлы</div>
    <div class="page-inner game-page-inner">
      <div class="page-inner-wrap">
        <div class="game-detail-top">
          <router-link class="btn btn-primary btn-medium btn-small btn-small" to="/">
            <span class="btn--text">
              <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 0.999756L2.34853 4.15123C1.8799 4.61986 1.8799 5.37965 2.34853 5.84828L5.5 8.99976" stroke="white" stroke-width="2" stroke-linecap="round"/>
              </svg>
              Назад
            </span>
          </router-link>
        </div>

        <div class="puzzle-top">
          <div class="puzzle-top-text">
            <p>Собери все <strong>25</strong> частей пазла</p>
            <p>чтобы получить <strong>главный приз!</strong></p>
          </div>

          <puzzleProgress :puzzle="userInfo.puzzle"></puzzleProgress>
        </div>

        <puzzleGrid :puzzle="userInfo.puzzle"></puzzleGrid>

        <div class="bottom-buttons">
            <button class="btn btn-primary" :class="{'disabled' : !finished}" @click="getPrize()"><span class='btn--text'>Открыть приз</span></button>
            <button class="btn btn-primary" @click="rulesPopup = true;"><span class='btn--text'>Правила игры</span></button>
        </div>

        <Modal
            v-model="rulesPopup"
            @close="rulesPopupClose"
            :title="'Правила'"
            class="modal-wrapper text-modal"
        >

          <div class="rules-content">
            <img src="@/assets/img/cup.png" class="cup-img" alt="Стаккан из лимитированной коллекции билайна!">
            <p>Войди в первую двадцатку собравших пазл и получи стакан из лимитированной коллекции билайна!</p>
            <p>А если не успел, не расстраивайся — мы дарим 1000 кристаллов каждому, кто справится с заданием.</p>
            <p>Погрузись в мир головоломок и выиграй призы!</p>
          </div>

        </Modal>

        <Modal
            v-model="promocodePopup"
            @close="promocodePopupClose"
            :title="'Поздравляем!'"
            class="prizeItem-modal modal-wrapper"
        >
        <p class="prizeItem-popup-text">{{prize.value ? 'Вы выиграли' : 'Вы выиграли. Приз скоро здесь появится!'}}</p>
        <div v-if="prize.value"  class="prizePromo-popup-content">
          <div class="prizeItem-modal-img">
            <img :src="getImageUrl(prize.value.prizeUrl)" alt="Суперприз" />
          </div>
          <div class="prizePromo-modal-wrap">
            <p class="prizePromo-title">{{prize.value.promocode.title}}</p>
            <p class="prizePromo-text">{{prize.value.promocode.description}}</p>
            <p class="prizePromo-instruction" v-html="prize.value.promocode.instruction"></p>
          </div>
        </div>
          <div v-if="prize.value" class="prizeItem-popup-btnGroup">
            <Code :code="prize.value.promocode.value" :toastSuccess="$t('common.copySuccess')" additionalСlasses='code-container-center code-container-bigButton'></Code>
            <a :href="prize.value.promocode.url" target="_blank" class="btn btn-primary"><span class="btn--text">Активировать</span></a>
          </div>
          <div v-else class="modal-buttons-large mt-0">
            <button  class="btn btn-primary" @click="rulesPopup = true;"><span class='btn--text'>Закрыть</span></button>
         </div>
        </Modal>



      <Modal
          v-model="diamondsPopup"
          @close="diamondsPopupClose"
          @confirm="diamondsPopupClose"
          :title="$t('common.dailyReward.congrats')"
          :btnText="$t('common.dailyReward.take')"
          :class="['modal-wrapper game-win-modal game-win-confetti']"
      >

        <div class="buy-popup-text">Вы выиграли</div>
        <div class="buy-popup-reward">
          <div class="buy-popup-reward-title">{{ $t('common.crystals', parseInt(this.prize.value))}}</div>
          <div class="buy-popup-reward-img">
            <img src="@/assets/img/crystall-popup.svg" alt="" />
          </div>
        </div>

      </Modal>


      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState } from 'pinia';
import puzzleProgress from '@/components/puzzle/progress.vue';
import puzzleGrid from '@/components/puzzle/grid.vue';
import Modal from '@/components/modals/Modal.vue'
import Code from '@/components/promo/codeBlock.vue'

export default {
  name: 'puzzle',
  components: {
    RouterLink,
    RouterView,
    puzzleProgress,
    puzzleGrid,
    Modal,
    Code
  },
  data() {
    return {
      progress: 0,
      rulesPopup: false,
      promocodePopup: false,
      diamondsPopup: false,
      puzzleObj: {},
      prize: {},
    };
  },
  computed: {
    ...mapState(useUserStore, ['puzzle','userInfo', 'staticPrefix']),
    finished() {
        return this.userInfo.puzzle.isDone
    },
  },
  created() {
    this.puzzleObj = this.puzzle;
  },
  methods: {
    rulesPopupClose() {
      this.rulesPopup = false
    },
    getImageUrl(url) {
      return new URL(url, this.staticPrefix).href
    },
    promocodePopupClose() {
      this.promocodePopup = false;
    },
    diamondsPopupClose() {
      this.diamondsPopup = false;
    },
    getPrize() {
      if (this.finished && this.prize) {
        this.prize = this.userInfo.puzzle.prize;
        if (this.prize.type === 'diamonds') {
          this.diamondsPopup = true;
        } else {
          this.promocodePopup = true;
        }
      } else {
        return;
      }
    }
  }
};
</script>
