{
    "common": {
      "copySuccess": "Buferga nusxalandi"
    },
    "title": "Текст",
    "description": "Описание",
    "policy": "Ma'lumotlarni qayta ishlash siyosati",
    "terms": "Foydalanish shartlari",
    "langTitle": "O'zbek",
    "nav": {
      "menu": {
        "home": "Uy",
        "shop": "Do'kon",
        "promo": "Promo",
        "profile": "Profil",
        "lang": "Til"
      },
      "back": "Orqaga"
    },
  "pages": {
    "main": {
      "pageTitle": "Uy"
    },
    "langs": {
      "pageTitle": "Til"
    },
    "promo": {
      "pageTitle": "Promo kodlari",
      "activate": "Faollashtirish"
    },
    "profile": {
      "pageTitle": "Profil",
      "winnersTitle": "G'oliblar ro'yxati",
      "downloadText": "PDF faylni yuklab oling"
    },
    "shop": {
      "pageTitle": "Do'kon",
      "popups": {
        "buy": {
          "title": "Kristallarni sotib olish",
          "text": "{price} shaxsiy hisobingizdan {phone} yechib olinadi va kreditlanadi",
          "crystals": "'0 kristall | {n} kristall | {n} kristall | {n} kristall',",
          "btnText": "Sotib olish"
        }
      }
    }
  }
}
