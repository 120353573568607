<template>
  <div class="page-container">
    <div class="page-title">Победители акции сервиса</div>
    <div class="page-inner content-layout">
      <div class="content-layout-top content-layout-top-winners">
        <router-link class="btn btn-primary btn-medium btn-small btn-small btn-left" :to="{name: 'profile'}">
          <span class="btn--text">
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 0.999756L2.34853 4.15123C1.8799 4.61986 1.8799 5.37965 2.34853 5.84828L5.5 8.99976" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
            Назад
        </span>
        </router-link>
        <div class="winners-title">{{winnersList.period}}</div>
      </div>
      <div class="content-layout-inner">
        <div class="winners-table-container">
          <table v-if="winnersList.winners && winnersList.winners.length > 0">
            <thead>
            <tr>
              <th>№</th>
              <th>Приз</th>
              <th>Победитель</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in winnersList.winners">
                <td>{{i+1 }}</td>
                <td>{{item.prize}}</td>
                <td>{{item.msisdn}}</td>
              </tr>
            </tbody>
          </table>
          <p v-if="winnersList.winners && winnersList.winners.length == 0" class="winners-title">Розыгрыш призов ещё не проводился</p>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView
  },
  async created() {
    await this.getWinners();
  },
  computed: {
    ...mapState(useUserStore, ['winners']),
    winnersList() {
     if (this.winners) {
       return this.winners
     }
     return false
    }
  },
  methods: {
    ...mapActions(useUserStore, ['getWinners']),
  }
};
</script>
