<template>
  <div class="page-container">
    <div class="page-title">{{сurrentGameName}}</div>
    <div class="page-inner game-page-inner">
      <div class="page-inner-wrap">
        <div class="game-detail-top game-innerPage-top">
          <router-link class="btn btn-primary btn-medium btn-small btn-absolute" to="/">
            <span class="btn--text">
              <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 0.999756L2.34853 4.15123C1.8799 4.61986 1.8799 5.37965 2.34853 5.84828L5.5 8.99976" stroke="white" stroke-width="2" stroke-linecap="round"/>
              </svg>
              Назад
          </span>
          </router-link>
          <div class="game-difficulty-title">Уровень №{{ currentLevel }}</div>
        </div>

        <div class="game-innerPage-preview" :class="$route.meta.gameType">
            <div class="">
              <img v-if="this.gameType === 'rebus' && this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-rebus-dark.svg" alt="Играть в ребусы">
              <img v-if="this.gameType === 'rebus' && !this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-rebus.svg" alt="Играть в ребусы">
              <img v-if="this.gameType === 'pic2' && this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-pic2-dark.svg" alt="Играть в ребусы">
              <img v-if="this.gameType === 'pic2' && !this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-pic2.svg" alt="Играть в ребусы">
              <img v-if="this.gameType === 'memorina' && this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-memorina-dark.svg" alt="Играть в ребусы">
              <img v-if="this.gameType === 'memorina' && !this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-memorina.svg" alt="Играть в ребусы">
              <img v-if="this.gameType === 'colors' && this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-lines-dark.svg" alt="Играть в ребусы">
              <img v-if="this.gameType === 'colors' && !this.darkTheme" class="game-innerPage-img " src="@/assets/img/game-lines.svg" alt="Играть в ребусы">
            </div>
        </div>

        <div class="game-bottom-buttons">

          <div :class="['btn btn-primary', {'disabled' : !hasActive}]" class="btn btn-primary" @click="continueGame()"><span class="btn--text">Продолжить</span></div>
          <div :class="['btn btn-primary', {'disabled' : LevelsBalanceEndIncludeActive}]" @click="NextLevelClick()"><span class="btn--text">{{getNextLevelBtnClick()}}</span></div>

        </div>


        <Modal
            v-model="continueGamePopUp"
            title="Уверены, что хотите начать новую игру?"
            @close="continueGamePopUpToggle"
        >
          <div class="popup-continueGames">
              У вас есть сохраненная игра, которую можно продолжить.
          </div>
          <template #customFooter>
            <div class="modal-buttons-large mt-0">
              <div @click="continueGame()" class="btn btn-primary"><span class="btn--text">Продолжить игру</span></div>
              <div @click="startGame()" class="btn btn-secondary"><span class="btn--text">Начать новую игру</span></div>
            </div>
          </template>

        </Modal>



        <Modal
            v-model="showEndGamePopUp"
            :title="'Уровни пройдены'"
            @close="confirmClose"
            class="modal-wrapper game-finished-modal"
            :clickToClose="false"
        >
          <div class="nogames-popup-text">
            Вы играли слишком много, все доступные уровни закончились. Попробуйте другую игру
          </div>
          <div class="buy-popup-reward">
            <img v-if="darkTheme" src="@/assets/img/no-games-dark.svg" class="no-games-img" alt="fail" />
            <img v-else src="@/assets/img/no-games-light.svg" class="no-games-img" alt="fail" />
          </div>

          <template #customFooter>
            <div class="modal-buttons-large">
              <RouterLink to="/" class="btn btn-primary"><span class="btn--text">На главную</span></RouterLink>
            </div>
            <div class="updates-date">Новые задания будут {{dateNewContent}}</div>
          </template>

        </Modal>


      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { useMainStore } from '@/store';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'

export default {
  name: 'Rebus',
  components: {
    RouterLink,
    RouterView,
    Modal
  },
  data() {
    return {
      noGames: false,
      gameType: '',
      continueGamePopUp: false,
      LimitedGamesPopup: false,
      finishedTotalPopUp: true,
      finishedTotalPopUpStartNew: false,
      gameLoad: false,
    };
  },
  async created() {
    this.gameType = this.$route.meta.gameType;
  },
  computed: {
    ...mapState(useMainStore, ['darkTheme']),
    ...mapState(useUserStore, ['userInfo', 'games']),
    haveCurrentState() {
      return !!localStorage.getItem(`${this.gameType}CurrentState`);
    },
    currentLevel(){
      let level = 0;
      if (this.userInfo){
        if (this.gameType === 'memorina' || this.gameType === 'colors') {
          level = this.userInfo.games[this.gameType].num;
        }
         else {
          level = this.userInfo.games[this.gameType].qtyCompleted + 1;
        }
      }
      return level
    },
    hasActive() {
      if (this.gameType === 'memorina') {
        return this.userInfo.games[this.gameType].num > 1 || this.userInfo.games[this.gameType].hasActive;
      }
      return this.userInfo.games[this.gameType].hasActive;
    },
    сurrentGameName() {
      const gamesName = {
        rebus: 'Ребусы',
        memorina: 'Меморина',
        pic2: 'Воображалка',
        colors: 'Игры цвета',
        default: '',
      }
        return gamesName[this.gameType] ? gamesName[this.gameType] : gamesName.default;
    },
    finishedLevelTotal() {
      if (this.userInfo.games[this.gameType].statsByLevels) {
        const availableLevelToal = this.userInfo.games[this.gameType].statsByLevels.available;
        return (availableLevelToal<=0 && this.finishedTotalPopUp) || (availableLevelToal<=1 && this.finishedTotalPopUpStartNew)
      }
      return false;
    },
    showEndGamePopUp() {
      return this.finishedLevelTotal || this.LimitedGamesPopup || this.LevelsBalanceEnd;
    },
    LevelsBalanceEnd() {
      if (this.userInfo.games[this.gameType].statsByLevels) {
        return !this.hasActive && this.userInfo.games[this.gameType].statsByLevels.month_levels_balance <= 0;
      }
      return false;
    },
    LevelsBalanceEndIncludeActive() {
      if (this.userInfo.games[this.gameType].statsByLevels) {
        return this.userInfo.games[this.gameType].statsByLevels.month_levels_balance <= 0;
      }
      return true;
    },
    dateNewContent() {
      if (this.userInfo.newContentWillBePublishedAt) {
        const datePublished = new Date(this.userInfo.newContentWillBePublishedAt);
        let dd = datePublished.getDate();
        dd = dd < 10 ? `0${dd}` : dd;

        let mm = datePublished.getMonth() + 1;
        mm = mm < 10 ? `0${mm}` : mm;

        let yy = datePublished.getFullYear();

        return dd + '.' + mm + '.' + yy;
      }
      return '';
    },
  },
  watch: {
    userInfo: function (val) {
      if (val.is_suspended == 1){
        this.$router.push('/');
        this.openSuspendModal();
      }
    },
  },
  methods: {
    ...mapActions(useUserStore, ['rebusGame', 'rebusGameNew', 'rebusGameRestart', 'openSuspendModal']),
    continueGamePopUpToggle() {
      this.continueGamePopUp = !this.continueGamePopUp;
    },
    getNextLevelBtnClick() {
      let btnSign = 'Новая игра';
      if (this.gameType ===  'rebus' || this.gameType === 'pic2') {
        if (this.userInfo.games[this.gameType].qtyCompleted > 0 || (this.userInfo.games[this.gameType].qtyCompleted === 0 && this.hasActive)) {
        btnSign = 'Следующий уровень'
        }
      }
      return btnSign;
    },
    NextLevelClick() {
      if (this.LevelsBalanceEndIncludeActive) {
        return;
      }
      if (this.hasActive) {
        this.continueGamePopUpToggle();
      } else {
        this.startGame();
      }
    },
    checkfinishedLevelTotal() {
      const maxAvailable = this.hasActive ? 1 : 0;
      return (this.userInfo.games[this.gameType].statsByLevels.available <= maxAvailable);
    },
   async startGame(){
      let response;
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      if (this.checkfinishedLevelTotal()) {
        this.finishedTotalPopUpStartNew = true;
        this.continueGamePopUp = false;
        return;
      }
      localStorage.removeItem(`${this.gameType}CurrentState`);
      response = await this.rebusGameNew(this.gameType);
      if (response){
       if (response.redirect)
         window.location.href = (response.redirect);
       else
        this.$router.push({name: `${this.gameType}game`})
      }
      else{
        this.LimitedGamesPopup = true;
      }
    },
    async continueGame(){
      let response;
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      if (this.gameType === 'colors') {
        this.$router.push({name: `${this.gameType}game`})
        return;
      } else {
        response = await this.rebusGame(this.gameType);
      }
      if (response){
        if (response.redirect)
          window.location.href = (response.redirect);
        else
          this.$router.push({name: `${this.gameType}game`})
      }
      else{
        this.LimitedGamesPopup = true;
      }
    },
    confirmClose(){
      this.finishedTotalPopUp = false;
      this.LimitedGamesPopup = false;
      this.finishedTotalPopUpStartNew = false;
    }
  }
};
</script>
