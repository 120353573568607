<template>
        <Modal
            v-model="showModal"
            :title="'Поздравляем!'"
            @close="confirmClose"
            :class="['modal-wrapper game-win-modal', {'game-win-confetti': !prizePromo || prizePromo.length === 0}]"
          >
          <div class="buy-popup-text">
            Вы прошли уровень №{{ numLevel}}<br>
            Ваши призы:
          </div>
          <div :class="['game-rewards', {'game-rewards-twoitems': counPrizes === 2, 'game-rewards-big' : !prizePromo || prizePromo.length === 0, 'smallMarging' : (!prizePromo || prizePromo.length === 0) && !haveNextLevel}]" v-if="prizes">
            <div class="reward points" v-if="prizes && prizes.points">+{{prizes.points}}</div>
            <div class="reward diamonds" v-if="prizes && prizes.diamonds">+{{prizes.diamonds}}</div>
            <div class="reward puzzle" v-if="prizes && prizes.puzzle">+{{prizes.puzzle}}</div>
          </div>
          <div v-if="!haveNextLevel">
            <p class="buy-popup-text">
              Вы прошли все доступные уровни!<br>
            </p>
            <p v-if="dateNewContent" class="game-rewards-update">
              Новые уровни будут {{dateNewContent}}   
            </p>
          </div>
          <template #customFooter>
            <div class="modal-buttons-large mt-0">
              <RouterLink to="/" class="btn btn-secondary"><span class="btn--text">Главное меню</span></RouterLink>
              <button v-if="haveNextLevel" class="btn btn-primary" @click="nextlevel()"><span class="btn--text">Следующий уровень</span></button>
            </div>
          </template>

        </Modal>


</template>


<script>
  import Modal from '@/components/modals/Modal.vue';
  import promoItem from '@/components/promo/promoItem.vue';

  export default {
    name: 'winModal',
    components: {
        promoItem,
    },
    data() {
        return {
            nextLevelClicked: false,
            prizePromo: [
                {promocode:
                {title: "Подписка на онлайн-кинотеатр Okko",
                value:"ut",
                url:"https://example.com",
                description:"Okko — это фильмы, сериалы, спектакли, концерты, караоке. Активируйте ваш промокод и 41 день смотрите 90 000 фильмов и сериалов в качестве Full HD и без рекламы.",
                description_short:"41 день в подарок на подписку Okko Оптимум",
                instruction: "1. Скопируйте промоактиспол167847381130"},
                partner:{
                    title:"Okko",
                    logo_url:"/storage/promocodes-partners/bd82e0be5f178d83c5b4043aa58ba5832b2407bb.jpg"}}
            ],
        }
    },
    props: {
        showWinModal: {
            type: Boolean,
            default: false,
        }, 
        numLevel: {
            type: Number,
            default: 0,
        },
        counPrizes: {
            type: Number,
            default: 0,
        },
        prizes: {
            type: Object,
            default: null,
        },
        haveNextLevel: {
            type: Boolean,
            default: false
        },
        dateNewContent: {
            type: String,
            default: ''
        },
    },
    computed: {
        showModal () {
            return this.showWinModal
        }
    },
    components: {
     Modal
    },
    methods: {
        confirmClose () {
            this.$emit('closePopUp');
        },
        nextlevel() {
            if (this.nextLevelClicked) {
                return;
            }
            this.$emit('nextlevel');
        },
    }
  };
</script>
