<template>
  <div class="page-container">
    <div class="page-title">Игры цвета</div>
    <div class="page-inner game-page-inner">
      <div class="page-inner-wrap">
        <div class="game-detail-top game-innerPage-top">
          <router-link class="btn btn-primary btn-medium btn-small btn-absolute" :to="{name: gameType}">
            <span class='btn--text'>
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 0.999756L2.34853 4.15123C1.8799 4.61986 1.8799 5.37965 2.34853 5.84828L5.5 8.99976" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
            Назад
            </span>
          </router-link>
          <div class="game-difficulty-title" v-if="gameData.state">Уровень №{{numLevel}}</div>
        </div>

        <div class="dev-panel" v-if="isDevMode">
          <div class="chain-preview" v-if="gameData.content">
            <span v-for="item in gameData.content.sequence">{{item + 1}},</span>
          </div>
          <div class="buttons">
            <button class="start-btn" @click="showNext" :disabled="levelPreview">Tip 1</button>
            <button class="start-btn" @click="previewByTip = true; startGame()" :disabled="levelPreview">Tip 2</button>
          </div>
        </div>

        <div class="chains-container" v-if="gameData.state" :class="[{loading: isLoading}]">

          <div class="chains-attempts">
            <div class="chains-attempts-dot" v-if="gameData.state" v-for="(item, ind) in maxAttempts" :class="{completed: gameData.state.attempts < ind+1}"></div>
          </div>
          <div class="countdown animate__bounceOut" v-if="counter" :class="{'animate__animated': counterAnim}">{{counterStep}}</div>
          <div class="chains-grid" :class="{previewActive: levelPreview}">
            <cell v-for="(item, index) in grid" :key="index" :item="item" :class="[`level-${gameData.state.steps-1}`, {highlighted: item.highlighted, highlightedTwice: item.highlightedTwice, error: item.error, highlightedByTip: item.highlightedByTip}]" @click="cellClick(item)"></cell>
          </div>
        </div>

        <tips :tips="tipsArr" @buy="buy" :loading="contentLoading"></tips>

        <Modal
            v-model="failModal"
            @close="restart"
            class="modal-wrapper game-lose-modal"
        >
          <div class="error-modal-title">Игра окончена</div>
          <div class="chains-attempts">
            <div class="chains-attempts-dot" v-if="gameData.state" v-for="(item, ind) in maxAttempts" :class="{completed: gameData.state.attempts < ind+1}"></div>
          </div>
          <div class="buy-popup-text" v-if="gameData.state.attempts > 0">
            Хотите пройти текущий уровень еще раз?
          </div>
          <div class="buy-popup-text" v-else>
            К сожалению, вы не выиграли, хотите попробовать еще раз?
          </div>
          <div class="buy-popup-reward">
            <img v-if="darkTheme" src="@/assets/img/lose-dark.svg" class="game-fail-img" alt="fail" />
            <img v-else src="@/assets/img/lose-light.svg" class="game-fail-img" alt="fail" />
          </div>

          <template #customFooter>
            <div class="modal-buttons-large mt-0">
              <div @click="restart()" class="btn btn-primary" v-if="haveNextLevel"><span class='btn--text'>Переиграть уровень</span></div>
              <div @click="resetGame()" class="btn btn-primary" v-else><span class='btn--text'>Новая игра</span></div>
              <RouterLink to="/" class="btn btn-secondary"><span class='btn--text'>Главное меню</span></RouterLink>
            </div>
          </template>

        </Modal>

        <Modal
            v-model="showWinModal"
            :title="numLevel === 23 ? 'Игра пройдена!' : 'Поздравляем!'"
            @close="closeWinPopUp"
            :class="['modal-wrapper game-win-modal', {'game-win-confetti': !prizePromo || prizePromo.length === 0}]"
        >
          <div class="buy-popup-text">
            Вы прошли уровень №{{numLevel}}<br>
            <span v-if="prizes">Ваши призы:</span>
          </div>
          <div :class="['game-rewards', {'game-rewards-twoitems': counPrizes === 2, 'game-rewards-big' : !prizePromo || prizePromo.length === 0, 'smallMarging' : (!prizePromo || prizePromo.length === 0) && !haveNextLevel}]" v-if="prizes">
            <div class="reward points" v-if="prizes && prizes.points">+{{prizes.points}}</div>
            <div class="reward diamonds" v-if="prizes && prizes.diamonds">+{{prizes.diamonds}}</div>
            <div class="reward puzzle" v-if="prizes && prizes.puzzle">+{{prizes.puzzle}}</div>
          </div>
          <template #customFooter>
            <div class="modal-buttons-large mt-0">
              <RouterLink to="/" class="btn btn-secondary"><span class='btn--text'>Главное меню</span></RouterLink>
              <button v-if="numLevel === 23" class="btn btn-primary" @click="resetGame()"><span class='btn--text'>Новая игра</span></button>
              <button v-else class="btn btn-primary" @click="nextlevel()"><span class='btn--text'>Следующий уровень</span></button>
            </div>
          </template>

        </Modal>

      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import tips from '@/components/games/tips.vue'
import Modal from '@/components/modals/Modal.vue'
import { useMainStore } from '@/store';
import infoImgPopUp from '@/components/games/pic2/infoImgPopUp.vue';
import cell from '@/components/games/lines/cell.vue'

let intervalId, countDowntimer;

export default {
  name: 'ColorsGame',
  components: {
    RouterLink,
    RouterView,
    tips,
    Modal,
    infoImgPopUp,
    cell
  },
  data() {
    return {
      gridSizeTotal: 12,
      maxAttempts: 10,
      levelPreview: true,
      errorCell: null,
      highlighted: null,
      gameData: {},
      gridArr: [],
      temp: [],
      failModal: false,
      isLoading: true,
      showWinModal: false,
      prizePromo: null,
      prizes: null,
      contentLoading: false,
      gameLose: false,
      counter: false,
      counterAnim: false,
      counterStep: 3,
      showNextActive: true,
      previewByTip: false,
      numLevel: '',
      checkProgress: false,
      tempArr: [],
      trialEnded: null
    };
  },
  async created() {
    this.gameType = this.$route.meta.gameType;
    await this.initGame();
  },
  mounted() {

  },
  computed: {
    ...mapState(useMainStore, ['darkTheme']),
    ...mapState(useUserStore, ['userInfo', 'games', 'staticPrefix' ,'isDevMode']),
    gameInfo() {
      return this.games[this.gameType]
    },
    gridSize(){
      return this.gridSizeTotal;
    },
    grid(){
      this.gridArr = [];
      for (let i = 0; i < this.gridSizeTotal; i++){
        let el = {}
        el.index = i;
        el.error = false;

        if (this.tempArr && this.tempArr.length > 0){
          this.tempArr.forEach(elTemp => {
            if (elTemp === i)
              el.highlighted = true;
          });
        }


        this.gridArr.push(el)
      }

      if (this.tempArr && this.tempArr.length > 0){
        this.tempArr.forEach(elTemp => {
          this.temp.push(elTemp);
        });
      }

      return this.gridArr;
    },
    haveNextLevel(){
      return this.gameData.state.attempts > 0
    },
    tipsArr(){
      let tips = [];
      tips = this.gameData.tips;
      if(tips)
        tips.forEach(el => el.disabled = this.checkDisabledTips(el));
      if(tips && this.levelPreview)
        tips.forEach(el => el.disabled = true);

      return tips;
    },
    counPrizes() {
      let count = 0;
      if (this.prizes.diamonds && this.prizes.diamonds > 0) ++count;
      if (this.prizes.points && this.prizes.points > 0) ++count;
      if (this.prizes.puzzle && this.prizes.puzzle > 0) ++count;
      return count;
    },
  },
  methods: {
    ...mapActions(useUserStore, ['rebusGame', 'checkGame', 'getUserInfo', 'buyTip', 'rebusGameNew', 'updateLinesAttempts', 'updateLinesLevel']),
    resetGame(){
      this.failModal = false;
      this.showWinModal = false;
      localStorage.removeItem(`${this.gameType}CurrentState`);
      this.initGame(true);
    },
    showNext(){
      let nextEl = this.gameData.content.sequence[this.temp.length];
      this.temp.push(nextEl)
      this.gridArr[nextEl].highlighted = true;
      if (this.temp.length > 12) {
        this.gridArr[nextEl].highlighted = false;
        this.gridArr[nextEl].highlightedTwice = true;
      }

      if (this.temp.length === this.gameData.state.steps){
        this.levelPreview = true;
        this.isLoading = true;


        setTimeout(() => {
          this.gridArr.map((obj) => {
            obj.highlighted = false;
            obj.error = false;
          })
        }, 1000);
        this.check(this.temp);
        this.temp = [];
      }
      if (this.temp.length >= this.gameData.state.steps - 1)
        this.showNextActive = false;

      this.previewActive = false;
      localStorage.setItem(`ColorsTemp`, JSON.stringify(this.temp));

    },
    checkDisabledTips(tip) {
      let status = false;
      if ((tip.id === 30 || tip.id === 31)) {
        status = false;
      }
      if (!this.showNextActive)
        status = true

      return status;
    },
    saveState() {
      const currentState = this.games[this.gameType].state;
      localStorage.setItem(`${this.gameType}CurrentState`, JSON.stringify(currentState));
    },
    async initGame (resetMode) {
      this.currentState = localStorage.getItem(`${this.gameType}CurrentState`);
      if (this.currentState) {
        let response = await this.rebusGame(this.gameType);

        if (response.redirect){
          window.location.href = (response.redirect);
          return;
        }

        if (response){
          this.gameData = this.games[this.gameType];
          this.saveState();
          this.isLoading = false;
        }
        this.isLoading = false;
      }
      else{
        if (resetMode){
          let response = await this.rebusGameNew(this.gameType);

          if (response.redirect){
            window.location.href = (response.redirect);
            return;
          }

          if (response){
            this.gameData = this.games[this.gameType];
            this.saveState();
            this.isLoading = false;
          }
        }
        else{
          if (this.games[this.gameType].content.id === '') {
            let response = await this.rebusGameNew(this.gameType);
            if (response.redirect){
              window.location.href = (response.redirect);
              return;
            }
          }
          this.gameData = this.games[this.gameType];
          this.saveState();
          this.isLoading = false;
        }
        localStorage.removeItem(`ColorsTemp`)
      }


      this.gameData = this.games[this.gameType];
      this.numLevel = this.gameData.state.num;

      if (localStorage.getItem(`ColorsTemp`)){
        this.tempArr = JSON.parse(localStorage.getItem(`ColorsTemp`))
        this.startGame();
      }
      else
        this.countdown();

    },
    countdown(){
      this.counter = true;
      this.counterAnim = false;
      if (this.counterStep > 0){
        countDowntimer = setInterval(() => {
          clearInterval(countDowntimer)
          this.counterStep--;
          this.counterAnim = true;
          this.countdown();
        }, 1001)
      }
      else{
        this.counter = false;
        clearInterval(countDowntimer)
        this.startGame();
      }


    },
    async buy(tip) {
      this.contentLoading = true;

      const response = await this.buyTip({
        'tip': tip.id,
        'answer': JSON.stringify(this.temp),
      }, this.gameType);

      this.contentLoading = false;

      if (response && tip.id===30 && this.showNextActive)
          this.showNext()

      if (response && tip.id===31 && this.showNextActive){
        this.highlightedByTip = true;
        this.previewByTip = true;
        this.startGame();
      }


    },
    closeWinPopUp() {
      if (this.numLevel === 23) {
        this.resetGame();
      } else {
        this.nextlevel();
      }
    },
    async startGame(){
      if(this.trialEnded){
        window.location.href = this.trialEnded;
        return;
      }
      clearInterval(intervalId);
      this.checkProgress = false;
      this.numLevel = this.gameData.state.num;
      this.levelPreview = true;
      this.showNextActive = true;
      if (!this.previewByTip && !localStorage.getItem(`ColorsTemp`) )
        this.temp = [];

      this.errorCell = null;
      this.gridArr.map((obj) => {
        if (!this.previewByTip){
          obj.highlighted = false;
          obj.highlightedTwice = false;
          obj.error = false;
        }
        else
          obj.highlightedByTip = false;
      })
      let counter = 0;

      if (localStorage.getItem(`ColorsTemp`) && !this.previewByTip){
        this.levelPreview = false;
      }
      else{
        intervalId = setInterval(() => {
        if(counter <= this.gameData.state.steps-1){


          this.gridArr.map((obj) => {
            if (!this.previewByTip)
              obj.highlighted = false;
            else
              obj.highlightedByTip = false;
          })
          if (!this.previewByTip){
            this.gridArr[this.gameData.content.sequence[counter]].highlighted = true;
          }
          else{
            this.gridArr[this.gameData.content.sequence[counter]].highlightedByTip = true;
          }
          counter++;
        }
        else{
          this.levelPreview = false;
          this.gridArr.map((obj) => {
            if (!this.previewByTip)
              obj.highlighted = false;
            else
              obj.highlightedByTip = false;
          })
          this.previewByTip = false;
          clearInterval(intervalId)
        }
      }, this.currentTimeValue());
      }

    },
    currentTimeValue() {
      let t = 1000;
      if (this.isDevMode) {
        t = 600;
      }
      return t;
    },
    cellClick(obj){
      if (this.checkProgress) {
        return
      }
      this.temp.push(obj.index);
      localStorage.setItem(`ColorsTemp`, JSON.stringify(this.temp));
      let hasError = false;
      obj.highlighted = true;
      if (this.temp.length > 12) {
        obj.highlighted = false;
        obj.highlightedTwice = true;
      }


      Object.keys(this.temp).forEach(el => {

        if (this.temp[el] != this.gameData.content.sequence[el]){
          hasError = true;
          this.gridArr[obj.index].error = true;
          this.levelPreview = true;

          setTimeout(() => {
            this.temp = [];
            this.gridArr.map((obj) => {
              obj.highlighted = false;
              obj.error = false;
            })
            this.isLoading = true;
            this.check(this.temp, obj);
            this.levelPreview = false;
          }, 500);

        }

      })

      if (this.temp.length === this.gameData.state.steps && !hasError){
        this.levelPreview = true;


        setTimeout(() => {
          this.gridArr.map((obj) => {
            obj.highlighted = false;
            obj.error = false;
          })
          this.isLoading = true;
          this.check(this.temp, obj);
        }, 500);
      }

      if (this.temp.length >= this.gameData.state.steps - 1)
        this.showNextActive = false;

    },
    async check(arr, obj){
      this.tempArr = [];
      this.isLoading = true;
      this.checkProgress = true;
      let response = await this.checkGame({
        gameType: this.gameType,
        params: {
          isFinal: 1,
          answer: JSON.stringify(arr),
        }
      });

      if (response.redirect){
       this.trialEnded = response.redirect;
      }

      if (response && response.state) {
        setTimeout(() =>  {
          this.userInfo.games[this.gameType].state = response.state;
          this.gameData.state = this.userInfo.games[this.gameType].state;
        }, 500)
        this.saveState();
        this.temp = [];
      }

      if (response && !response.result && !Array.isArray(response.round)){
        setTimeout(() =>  {
          this.showWinModal = true;
          if (response.round) {
            this.userInfo.diamonds+= response.round.diamonds;
            this.userInfo.points+= response.round.points;
            this.userInfo.puzzle+= response.round.puzzleQty;
          }

          if (response.round.points > 0 || response.round.puzzleQty > 0 || response.round.diamonds > 0){
            this.prizes = {};
            this.prizes.points = response.round.points;
            this.prizes.puzzle = response.round.puzzleQty;
            this.prizes.diamonds = response.round.diamonds;
          }

          this.isLoading = false;
        }, 500);
      }

      if (response && !response.result && Array.isArray(response.round)){
        this.isLoading = false;
        this.failModal = true;
      }

      if (response && response.result=='fail'){
        this.isLoading = false;
        localStorage.removeItem(`${this.gameType}CurrentState`);
        this.gameData.state.attempts = 0;
        this.gameLose = false;
        this.failModal = true;
      }


      if (response && response.result){
        this.userInfo.games[this.gameType].qtyCompleted = response.qty;
        this.userInfo.games[this.gameType].qtySuccess = response.qty_success;
        this.userInfo.diamonds+= response.diamonds;
        this.userInfo.points+= response.points;
        this.userInfo.puzzle+= response.puzzleQty;
      }
      if (response.result == 'win'){
        localStorage.removeItem(`${this.gameType}CurrentState`);
        this.showWinModal = true;
        if (response.points > 0 || response.puzzleQty > 0 || response.diamonds > 0){
          this.prizes = {};
          this.prizes.points = response.points;
          this.prizes.puzzle = response.puzzleQty;
          this.prizes.diamonds = response.diamonds;
        }

        if (response.prizeContent && response.prizeContent.length > 0){
          this.prizePromo = response.prizeContent
        }
      }

      localStorage.removeItem(`ColorsTemp`);

    },
    nextlevel(){
      this.showWinModal = false;
      this.failModal = false;
      this.isLoading = false;
      this.startGame();
    },
    failModalСonfirmClose() {
      this.failModal = false;
      this.isLoading = false;

    },
    restart(){

      if(this.trialEnded){
        window.location.href = this.trialEnded;
        return;
      }

      if (this.gameData.state.attempts === 0) {
        this.resetGame();
      }
      else{
        this.startGame();
      }
      this.isLoading = false;
      this.failModal = false;
      this.saveState();
    }
  },
  beforeRouteLeave(to, from) {
    this.userInfo.games[this.gameType].num = this.gameData.state.num;
    this.getUserInfo();
  },
};
</script>
