{
    "currency": "Текст",
    "common": {
      "copySuccess": "Скопировано в буфер обмена",
      "dailyReward": {
        "congrats": "Поздравляем!",
        "text": "Ваш ежедневный бонус",
        "take": "Забрать"
      },
      "crystals": "0 Кристаллов | {n} Кристалл | {n} Кристаллов"
    },
    "title": "Текст",
    "description": "Описание",
    "policy": "Политика обработки данных",
    "terms": "Пользовательское соглашение",
    "langTitle": "Русский",
    "nav": {
      "menu": {
        "home": "Главная",
        "shop": "Магазин",
        "promo": "Промокоды",
        "profile": "Профиль",
        "lang": "Язык"
      },
      "back": "Назад"
    },
  "pages": {
    "main": {
      "pageTitle": "Главная"
    },
    "langs": {
      "pageTitle": "Язык"
    },
    "promo": {
      "pageTitle": "Промокоды",
      "activate": "Активировать"
    },
    "profile": {
      "pageTitle": "Профиль",
      "winnersTitle": "Список победителей",
      "downloadText": "Скачать PDF-файл"
    },
    "shop": {
      "pageTitle": "Магазин",
      "popups": {
        "buy": {
          "title": "Покупка кристаллов",
          "text": "С вашего лицевого счёта {phone} будет списано {price} и начислено",
          "text_star": "С вашего игрового счёта будет списано {price} и начислено",
          "crystals": "0 Кристаллов | {n} Кристалл | {n} Кристаллов",
          "btnText": "Купить"
        }
      }
    },
    "games": {
      "difficulty": "Выбор сложности",
      "activate": "Активировать"
    }
  }
}
