<template>
  <div class="nav-container">
    <RouterLink class="nav-item" to="/">
  
      <div class="nav-icon">
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.625 13.673C3.625 12.1428 4.32572 10.6968 5.52678 9.7486L12.4018 4.32097C14.2183 2.88685 16.7817 2.88685 18.5982 4.32096L25.4732 9.74859C26.6743 10.6968 27.375 12.1428 27.375 13.673V21.875C27.375 24.6364 25.1364 26.875 22.375 26.875H20.5C19.8096 26.875 19.25 26.3154 19.25 25.625V21.875C19.25 20.4943 18.1307 19.375 16.75 19.375H14.25C12.8693 19.375 11.75 20.4943 11.75 21.875V25.625C11.75 26.3154 11.1904 26.875 10.5 26.875H8.625C5.86358 26.875 3.625 24.6364 3.625 21.875L3.625 13.673Z" fill="currentColor"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.home") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" :to="{ name: 'shop'}">
      <div class="nav-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.9591 20.625H9.71794C8.45243 20.625 7.38652 19.6794 7.23574 18.4229L6 8.125H23.8178C25.6882 8.125 27.0606 9.88274 26.6069 11.6973L25.2035 17.3111C24.7166 19.2587 22.9667 20.625 20.9591 20.625Z" fill="currentColor"/>
          <path d="M2.5 3.75L3.8117 4.01234C4.8892 4.22784 5.69966 5.12165 5.809 6.21503L6 8.125M6 8.125L7.23574 18.4229C7.38652 19.6794 8.45243 20.625 9.71794 20.625H20.9591C22.9666 20.625 24.7166 19.2587 25.2035 17.3111L26.6069 11.6973C27.0606 9.88274 25.6882 8.125 23.8178 8.125H6Z" stroke="currentColor" stroke-width="1.875" stroke-linecap="round"/>
          <circle cx="10.625" cy="25" r="1.875" fill="currentColor"/>
          <circle cx="21.875" cy="25" r="1.875" fill="currentColor"/>
          <path class="nav-icon--addLine" d="M16.25 16.875H11.25" stroke="currentColor" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.shop") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" :to="{ name: 'profile'}">
      <div class="nav-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 20 3.75)" fill="currentColor" stroke="currentColor" stroke-width="1.875"/>
          <path d="M6.25 21.1683C6.25 20.0929 6.92607 19.1335 7.93886 18.7718C12.5051 17.1411 17.4949 17.1411 22.0611 18.7718C23.0739 19.1335 23.75 20.0929 23.75 21.1683V22.8127C23.75 24.297 22.4354 25.4372 20.966 25.2273L19.773 25.0569C16.6071 24.6046 13.3929 24.6046 10.227 25.0569L9.03401 25.2273C7.56463 25.4372 6.25 24.297 6.25 22.8127V21.1683Z" fill="currentColor" stroke="currentColor" stroke-width="1.875"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.profile") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" :to="{ name: 'langs'}">
      <div class="nav-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9996 3.3687C16.368 3.3687 17.8871 6.0234 18.5645 10.211L11.4348 10.2108C12.1122 6.02353 13.6313 3.3687 14.9996 3.3687Z" fill="currentColor"/>
          <path d="M18.934 15.0001C18.9345 16.143 18.8726 17.2851 18.7492 18.4211H11.223C10.9765 16.1472 10.9765 13.853 11.223 11.5794H18.7492C18.8726 12.7154 18.9345 13.8572 18.934 15.0001Z" fill="currentColor"/>
          <path d="M14.9999 26.6317C13.6315 26.6317 12.1125 23.977 11.435 19.7894L18.5647 19.7896C17.8873 23.9769 16.3682 26.6317 14.9999 26.6317Z" fill="currentColor"/>
          <path d="M17.7368 26.2965C18.8041 24.7912 19.5501 22.4238 19.9537 19.7897H25.5985C24.867 21.399 23.7788 22.821 22.4169 23.9483C21.0549 25.0755 19.4545 25.8785 17.7368 26.2965Z" fill="currentColor"/>
          <path d="M20.1178 18.4209C20.2412 17.2848 20.3029 16.1428 20.3026 14.9999C20.3029 13.857 20.2412 12.7149 20.1178 11.5789H26.1185C26.8046 13.8079 26.8046 16.1918 26.1185 18.4206L20.1178 18.4209Z" fill="currentColor"/>
          <path d="M25.5846 10.2106H19.9398C19.5362 7.57625 18.7902 5.20886 17.7229 3.70369C19.4406 4.12178 21.041 4.92472 22.403 6.05185C23.7649 7.17922 24.8528 8.60122 25.5846 10.2106Z" fill="currentColor"/>
          <path d="M12.2627 3.70385C11.1954 5.2092 10.4495 7.57659 10.0458 10.2107H4.41478C5.14511 8.60297 6.23101 7.1819 7.59039 6.05483C8.94982 4.9277 10.5475 4.12376 12.2627 3.70385Z" fill="currentColor"/>
          <path d="M3.36835 15.0004C3.36972 13.841 3.54269 12.6882 3.88151 11.5794H9.88225C9.63598 13.8533 9.63598 16.1475 9.88225 18.4211H3.88151C3.54269 17.3124 3.36972 16.1598 3.36835 15.0004Z" fill="currentColor"/>
          <path d="M4.41531 19.79H10.0601C10.4637 22.4243 11.2097 24.7917 12.277 26.2968C10.5593 25.8787 8.95862 25.0758 7.59667 23.9487C6.23471 22.8213 5.14705 21.3993 4.41531 19.79Z" fill="currentColor"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.lang") }}</div>
    </RouterLink>
  </div>
</template>

<script>
  import { RouterLink, RouterView } from 'vue-router'

  export default {
    name: 'Nav',
    components: {
      RouterLink,
      RouterView
    },
  };
</script>
