<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.langs.pageTitle") }}</div>

    <div class="page-inner">
      <div class="page-inner-wrap">
        <div class="langs-list">

          <label class="langs-list-item">
            <input type="radio" v-model="$i18n.locale" :value="'ru'">
            <div class="langs-list-item-content">
              <img src="@/assets/img/locales/ru.png" alt="" class="langs-list-item-img" />
              <div class="langs-list-item-title">Русский</div>
            </div>
          </label>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import { RouterLink, RouterView } from 'vue-router'

export default {
  components: {
    RouterLink,
    RouterView
  },
};
</script>
