<template>
  <div class="winMonthPromoBanner">
    <div class="winMonthPromoBanner__banner" @click="showMonthPromoInfo"  v-if="promoItem.isMoney">
      <p class="winMonthPromoBanner__textBanner">Вы выиграли приз!</p>
      <p class="winMonthPromoBanner__textBanner winMonthPromoBanner__textBanner--big">{{promoItem.titleShort}}</p>
    </div>
    <div @click="showMonthPromoInfo" class="winMonthPromoBanner__banner--disc"  v-if="promoItem.isDiscount">
      <p class="winMonthPromoBanner__textBanner winMonthPromoBanner__textBanner--big winMonthPromoBanner__textBanner--disc">{{promoItem.titleShort}}</p>
    </div>
    <div class="winMonthPromoBanner__info">
      <p @click="showMonthPromoInfo" :class="['winMonthPromoBanner__infoText', {'winMonthPromoBanner__infoText--disc' : promoItem.isDiscount}]">{{this.promoItem.title}}</p>
      <div class="winMonthPromoBanner__btnGroup" v-if="promoItem.isDiscount">
          <Code :code="promoItem.promocode" :toastSuccess="$t('common.copySuccess')" additionalСlasses='winMonthPromoBanner__code'></Code>
          <a href="https://moskva.beeline.ru/shop/" target="_blank" class="btn btn-primary  btn-medium"><span class='btn--text'>Активировать</span></a>
      </div>
    </div>

    <Modal
        v-model="monthPromoInfo"
        @close="closeMonthPromoInfo"
        class="modal-wrapper promo-modal"
    >

      <div class="promo-text promo-text-large" v-if="promoItem.title" v-html="promoItem.title"></div>
      <div class="promo-text" v-if="promoItem.description" v-html="promoItem.description"></div>

      <template #customFooter v-if="promoItem.isDiscount">
        <div class="promo-item-bottom promo-item-bottom-bigMargin">
          <Code :code="promoItem.promocode" :toastSuccess="$t('common.copySuccess')" v-if="promoItem.promocode"></Code>
          <a href="https://moskva.beeline.ru/shop/" target="_blank" class="promo-item-link btn btn-medium btn-primary"><span class='btn--text'>{{ $t("pages.promo.activate") }}</span></a>
        </div>
      </template>

    </Modal>

  </div>
</template>


<script>
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import Code from '@/components/promo/codeBlock.vue';


export default {
  name: 'winMonthPromoBanner',
  props: {
    promoItem: {
      type: Object,
      default: null,
    }
  },
  components: {
    Code,
    Modal
  },
  data() {
    return {
      monthPromoInfo: true,
    };
  },
  computed: {
    titleText () {
      return this.promoItem.isDiscount ? this.promoItem.title : `<p class="winMonthPromoBanner__infoText--200">${this.promoItem.title}</p>`;
    },
  },
  methods: {
    showMonthPromoInfo () {
      this.monthPromoInfo = true;
    },
    closeMonthPromoInfo() {
      this.monthPromoInfo = false;
    },
  }
};
</script>
