<template>
  <div class="chain-grid-item">
   <div class="chain-grid-item-content"></div>
  </div>
</template>

<script>
import { useUserStore } from '@/store/user';
import { mapActions } from 'pinia';


export default {
    name: 'cell',
    props: {
        item: {
            tips: Object,
            default: {}
        },
    },
    computed: {

    },
    methods: {
        ...mapActions(useUserStore, ['buyTip']),
        saveState() {
            this.$emit('saveState');
        },
        check() {
            this.$emit('check');
        },
    },
}

</script>
