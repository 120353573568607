<template>
  <div class="nav-container">
    <RouterLink class="nav-item" to="/">

      <div class="nav-icon">
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.625 13.673C3.625 12.1428 4.32572 10.6968 5.52678 9.7486L12.4018 4.32097C14.2183 2.88685 16.7817 2.88685 18.5982 4.32096L25.4732 9.74859C26.6743 10.6968 27.375 12.1428 27.375 13.673V21.875C27.375 24.6364 25.1364 26.875 22.375 26.875H20.5C19.8096 26.875 19.25 26.3154 19.25 25.625V21.875C19.25 20.4943 18.1307 19.375 16.75 19.375H14.25C12.8693 19.375 11.75 20.4943 11.75 21.875V25.625C11.75 26.3154 11.1904 26.875 10.5 26.875H8.625C5.86358 26.875 3.625 24.6364 3.625 21.875L3.625 13.673Z" fill="currentColor"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.home") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" :to="{ name: 'shop'}">
      <div class="nav-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.9591 20.625H9.71794C8.45243 20.625 7.38652 19.6794 7.23574 18.4229L6 8.125H23.8178C25.6882 8.125 27.0606 9.88274 26.6069 11.6973L25.2035 17.3111C24.7166 19.2587 22.9667 20.625 20.9591 20.625Z" fill="currentColor"/>
          <path d="M2.5 3.75L3.8117 4.01234C4.8892 4.22784 5.69966 5.12165 5.809 6.21503L6 8.125M6 8.125L7.23574 18.4229C7.38652 19.6794 8.45243 20.625 9.71794 20.625H20.9591C22.9666 20.625 24.7166 19.2587 25.2035 17.3111L26.6069 11.6973C27.0606 9.88274 25.6882 8.125 23.8178 8.125H6Z" stroke="currentColor" stroke-width="1.875" stroke-linecap="round"/>
          <circle cx="10.625" cy="25" r="1.875" fill="currentColor"/>
          <circle cx="21.875" cy="25" r="1.875" fill="currentColor"/>
          <path class="nav-icon--addLine" d="M16.25 16.875H11.25" stroke="currentColor" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.shop") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" :to="{ name: 'promo'}">
      <div class="nav-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 10C2.5 7.23858 4.73858 5 7.5 5H22.5C25.2614 5 27.5 7.23858 27.5 10V11.5288C27.5 11.7666 27.3307 11.9674 27.1059 12.0449C25.8804 12.4675 25 13.6309 25 15C25 16.3691 25.8804 17.5325 27.1059 17.9551C27.3307 18.0326 27.5 18.2334 27.5 18.4712V20C27.5 22.7614 25.2614 25 22.5 25H7.5C4.73858 25 2.5 22.7614 2.5 20V18.4712C2.5 18.2334 2.66925 18.0326 2.89407 17.9551C4.1196 17.5325 5 16.3691 5 15C5 13.6309 4.1196 12.4675 2.89407 12.0449C2.66925 11.9674 2.5 11.7666 2.5 11.5288V10Z" fill="currentColor"/>
          <path d="M2.89407 17.9551L3.19965 18.8414L2.89407 17.9551ZM27.1059 12.0449L27.4115 12.9312L27.1059 12.0449ZM7.5 4.0625C4.22081 4.0625 1.5625 6.72081 1.5625 10H3.4375C3.4375 7.75634 5.25634 5.9375 7.5 5.9375V4.0625ZM22.5 4.0625H7.5V5.9375H22.5V4.0625ZM28.4375 10C28.4375 6.72081 25.7792 4.0625 22.5 4.0625V5.9375C24.7437 5.9375 26.5625 7.75634 26.5625 10H28.4375ZM28.4375 11.5288V10H26.5625V11.5288H28.4375ZM25.9375 15C25.9375 14.0431 26.5525 13.2274 27.4115 12.9312L26.8003 11.1586C25.2083 11.7075 24.0625 13.2187 24.0625 15H25.9375ZM27.4115 17.0688C26.5525 16.7726 25.9375 15.9569 25.9375 15H24.0625C24.0625 16.7813 25.2083 18.2925 26.8003 18.8414L27.4115 17.0688ZM28.4375 20V18.4712H26.5625V20H28.4375ZM22.5 25.9375C25.7792 25.9375 28.4375 23.2792 28.4375 20H26.5625C26.5625 22.2437 24.7437 24.0625 22.5 24.0625V25.9375ZM7.5 25.9375H22.5V24.0625H7.5V25.9375ZM1.5625 20C1.5625 23.2792 4.22081 25.9375 7.5 25.9375V24.0625C5.25634 24.0625 3.4375 22.2437 3.4375 20H1.5625ZM1.5625 18.4712V20H3.4375V18.4712H1.5625ZM3.19965 18.8414C4.79167 18.2925 5.9375 16.7813 5.9375 15H4.0625C4.0625 15.9569 3.44753 16.7726 2.58848 17.0688L3.19965 18.8414ZM5.9375 15C5.9375 13.2187 4.79167 11.7075 3.19965 11.1586L2.58848 12.9312C3.44753 13.2274 4.0625 14.0431 4.0625 15H5.9375ZM1.5625 10V11.5288H3.4375V10H1.5625ZM3.19965 11.1586C3.28316 11.1874 3.4375 11.2974 3.4375 11.5288H1.5625C1.5625 12.2358 2.05535 12.7474 2.58848 12.9312L3.19965 11.1586ZM3.4375 18.4712C3.4375 18.7026 3.28316 18.8126 3.19965 18.8414L2.58848 17.0688C2.05535 17.2526 1.5625 17.7642 1.5625 18.4712H3.4375ZM26.8003 18.8414C26.7168 18.8126 26.5625 18.7026 26.5625 18.4712H28.4375C28.4375 17.7642 27.9446 17.2526 27.4115 17.0688L26.8003 18.8414ZM26.5625 11.5288C26.5625 11.2974 26.7168 11.1874 26.8003 11.1586L27.4115 12.9312C27.9447 12.7474 28.4375 12.2358 28.4375 11.5288H26.5625Z" fill="currentColor"/>
          <circle class="nav-icon--addLine" cx="11.4131" cy="11.4121" r="1.28994" transform="rotate(45 11.4131 11.4121)" fill="currentColor" stroke="currentColor"/>
          <circle class="nav-icon--addLine" cx="18.5884" cy="18.5874" r="1.28994" transform="rotate(45 18.5884 18.5874)" fill="currentColor" stroke="currentColor"/>
          <path class="nav-icon--addLine" d="M11.3522 18.6478L18.6492 11.3508" stroke="currentColor" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.promo") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" :to="{ name: 'profile'}">
      <div class="nav-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 20 3.75)" fill="currentColor" stroke="currentColor" stroke-width="1.875"/>
          <path d="M6.25 21.1683C6.25 20.0929 6.92607 19.1335 7.93886 18.7718C12.5051 17.1411 17.4949 17.1411 22.0611 18.7718C23.0739 19.1335 23.75 20.0929 23.75 21.1683V22.8127C23.75 24.297 22.4354 25.4372 20.966 25.2273L19.773 25.0569C16.6071 24.6046 13.3929 24.6046 10.227 25.0569L9.03401 25.2273C7.56463 25.4372 6.25 24.297 6.25 22.8127V21.1683Z" fill="currentColor" stroke="currentColor" stroke-width="1.875"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.profile") }}</div>
    </RouterLink>
    <RouterLink class="nav-item" :to="{ name: 'langs'}">
      <div class="nav-icon">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9996 3.3687C16.368 3.3687 17.8871 6.0234 18.5645 10.211L11.4348 10.2108C12.1122 6.02353 13.6313 3.3687 14.9996 3.3687Z" fill="currentColor"/>
          <path d="M18.934 15.0001C18.9345 16.143 18.8726 17.2851 18.7492 18.4211H11.223C10.9765 16.1472 10.9765 13.853 11.223 11.5794H18.7492C18.8726 12.7154 18.9345 13.8572 18.934 15.0001Z" fill="currentColor"/>
          <path d="M14.9999 26.6317C13.6315 26.6317 12.1125 23.977 11.435 19.7894L18.5647 19.7896C17.8873 23.9769 16.3682 26.6317 14.9999 26.6317Z" fill="currentColor"/>
          <path d="M17.7368 26.2965C18.8041 24.7912 19.5501 22.4238 19.9537 19.7897H25.5985C24.867 21.399 23.7788 22.821 22.4169 23.9483C21.0549 25.0755 19.4545 25.8785 17.7368 26.2965Z" fill="currentColor"/>
          <path d="M20.1178 18.4209C20.2412 17.2848 20.3029 16.1428 20.3026 14.9999C20.3029 13.857 20.2412 12.7149 20.1178 11.5789H26.1185C26.8046 13.8079 26.8046 16.1918 26.1185 18.4206L20.1178 18.4209Z" fill="currentColor"/>
          <path d="M25.5846 10.2106H19.9398C19.5362 7.57625 18.7902 5.20886 17.7229 3.70369C19.4406 4.12178 21.041 4.92472 22.403 6.05185C23.7649 7.17922 24.8528 8.60122 25.5846 10.2106Z" fill="currentColor"/>
          <path d="M12.2627 3.70385C11.1954 5.2092 10.4495 7.57659 10.0458 10.2107H4.41478C5.14511 8.60297 6.23101 7.1819 7.59039 6.05483C8.94982 4.9277 10.5475 4.12376 12.2627 3.70385Z" fill="currentColor"/>
          <path d="M3.36835 15.0004C3.36972 13.841 3.54269 12.6882 3.88151 11.5794H9.88225C9.63598 13.8533 9.63598 16.1475 9.88225 18.4211H3.88151C3.54269 17.3124 3.36972 16.1598 3.36835 15.0004Z" fill="currentColor"/>
          <path d="M4.41531 19.79H10.0601C10.4637 22.4243 11.2097 24.7917 12.277 26.2968C10.5593 25.8787 8.95862 25.0758 7.59667 23.9487C6.23471 22.8213 5.14705 21.3993 4.41531 19.79Z" fill="currentColor"/>
        </svg>
      </div>
      <div class="nav-title">{{ $t("nav.menu.lang") }}</div>
    </RouterLink>
  </div>
</template>

<script>
  import { RouterLink, RouterView } from 'vue-router'

  export default {
    name: 'Nav',
    components: {
      RouterLink,
      RouterView
    },
  };
</script>
