import { ref, computed } from 'vue'
import { defineStore } from 'pinia';

const getTheme = () => {
  const localStorageTheme = localStorage.getItem('rebusDarkTheme');
  if (localStorageTheme) {
    return JSON.parse(localStorageTheme);
  } 
  return false;
}

export const useMainStore = defineStore('main', {

  state: () => ({
    modalVisible: false,
    modal: {},
    isMobile: true,
    dailyReward: false,
    darkTheme: getTheme(),
  }),
  getters: {
  },
  actions: {
    hideModal() {
      this.modalVisible = false;
      this.modal = {};
    },
    setDeviceAsDesktop() {
      this.isMobile = false;
    },
    buyConfirm() {
      return ( Math.floor(Math.random() * (1 - 0 + 1) + 0) === 1 ? true : false )
    },
    switchTheme(check) {
      this.darkTheme = check;
      localStorage.setItem(`rebusDarkTheme`, this.darkTheme);
    }
  },
});
