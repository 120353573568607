<template>

  <RouterLink :class='["puzzle-panel", {"puzzle-panel-all" : isDone && availableGames}, {"puzzle-panel-withTimer" : !availableGames}]' :to="{ name: 'puzzle'}" >
   <div v-if="!isDone && availableGames" class="puzzle-panel-title">Мои пазлы</div>
    <div v-if="!isDone && availableGames"  class="puzzle-panel-pieces">{{ pieces() }} шт.</div>
    <div v-if="isDone && availableGames" class="puzzle-panel-title puzzle-panel-smallTitle puzzle-panel-allTitle">Вы собрали весь&nbsp;пазл!</div> 
    <div v-if="!availableGames" class="puzzle-panel-title puzzle-panel-smallTitle">Задания обновятся через:</div>
    <div v-if="!availableGames" class="puzzle-panel-timer">
      {{hoursText}}:<span class='timer-span'>{{minutesText}}</span>:<span class='timer-span'>{{secondsText}}</span>
    </div>
  </RouterLink>

</template>


<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';

export default {
  name: 'puzzleProgress',
  props: {
    puzzle: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    },
    availableGames: {
      type: Boolean,
      default: true
    },
    dateUpdate: {
      type: String,
      default: null
    }
  },
  components: {

  },
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
      endMonth: '',
      timerId: null,
      deadline: null,
      hoursText: '',
      minutesText: '',
      secondsText: '',
    };
  },
  computed: {
    isDone() {
      return this.info.puzzle.isDone; 
    },
  },
  created() {

  },
  methods: {
    pieces () {
      let pieces = 0;
      if (this.info.puzzle.openedItems)
        pieces = this.info.puzzle.openedItems.length
      return pieces;
    },
    MonthTime() {
      const diff = this.deadline - new Date();
      if (diff <= 0) {
        this.secondStext = '00';
        this.minutesStext = '00';
        this.hoursStext = '00';
        clearInterval(this.timerId);
      }
      const hours = diff > 0 ? Math.floor(diff / 3600000) : 0;
      const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
      const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
      this.secondsText = seconds < 10 ? `0${seconds}` : seconds;
      this.minutesText = minutes < 10 ? `0${minutes}` : minutes;
      this.hoursText = hours < 10 ? `0${hours}` : hours;
    },
    createTimer() {
      if (this.dateUpdate && !this.availableGames) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() === 11 ? 1 : currentDate.getMonth() + 1;
        const currentYear = currentMonth === 1 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
        this.deadline = new Date(this.dateUpdate);
        this.timerId = setInterval(this.MonthTime, 1000);
      }
    },
  },
  updated() {
   this.createTimer();
  },
  mounted(){
    this.createTimer();
  },
  unmounted() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
  
};
</script>
