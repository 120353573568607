<template>
        <Modal
            v-model="showModal"
            :title="'Время истекло'"
            @close="restartLevel()"
            :class="['modal-wrapper game-fail-modal']"
          >
          <div class="memorina__timer">
            <img src="@/assets/icons/timer.svg" width="36" height="36" alt="Таймер">
            <span class="memorina__timerValue">00:00</span>
          </div>
          <div class="buy-popup-text">
            Хотите пройти текущий уровень еще раз?
          </div>
          <div class="buy-popup-reward">
            <img v-if="darkTheme" src="@/assets/img/lose-dark.svg" class="game-fail-img" alt="fail" />
            <img v-else src="@/assets/img/lose-light.svg" class="game-fail-img" alt="fail" />
          </div>
          <template #customFooter>
            <div class="modal-buttons-large mt-0">
              <button  class="btn btn-primary" @click="restartLevel()"><span class="btn--text">Начать заново</span></button>
              <button  class="btn btn-secondary" @click="restartGame()"><span class="btn--text">Начать новую игру</span></button>
            </div>
          </template>

        </Modal>


</template>


<script>
  import Modal from '@/components/modals/Modal.vue';
  import { useMainStore } from '@/store';
  import { mapState, mapActions } from 'pinia';

  export default {
    name: 'failModal',
    props: {
        showFailModal: {
            type: Boolean,
            default: false,
        }, 
    },
    computed: {
      ...mapState(useMainStore, ['darkTheme']),
        showModal () {
            return this.showFailModal
        }
    },
    components: {
     Modal
    },
    methods: {
      restartLevel () {
          this.$emit('restartLevel');
      },
      restartGame () {
          this.$emit('restartGame');
      },
    }
  };
</script>
