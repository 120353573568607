<template>
    <div>
        <div class="rebus-field-wrap">
            <div  class="rebus-field">
                <div v-for="item in answerArray" class="rebus-field-letter" style="cursor: default" :id="`rebus-field-letter-${item.id}`" :key="item.id" ref='rebus-field' @click="removeLetter($event.currentTarget, item)">
                </div>
            </div>   
        </div>
        <div  class="rebus-lettersBlock" id="lettersBlock">
            <div v-for="item in lettersArray" class="rebus-lettersBlock-letterWrap"  :id="`rebus-lettersBlock-letter-${item.id}`" style="cursor: pointer"  :key="item.id" @click="addLetter($event.currentTarget, item)">
                <p class='rebus-lettersBlock-letter' :style="[{ top: `${item.top}px`, transform: `rotate(${item.rotate}deg)`, opacity: 1}]" >{{item.value}}</p>   
            </div>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/store/user';
import { mapActions } from 'pinia';


export default {
    name: 'lettersField',
    beforeUpdate() {
        const tipLetters = document.querySelectorAll(`.rebus-lettersBlock-letterTip`);
        const letterWraps = document.querySelectorAll(`.rebus-lettersBlock-letterWrap`);
        const fieldLetters = document.querySelectorAll(`.rebus-field-letter`);
        tipLetters.forEach(el => {
                el.classList.remove('rebus-lettersBlock-letterTip');
                el.style.cursor = 'pointer';
            });

        letterWraps.forEach(el => el.style.cursor = 'pointer');
        fieldLetters.forEach(el => el.style.cursor = 'default');
    },
    mounted() {
        if (this.haveCurrentState) {
        this.lettersArray.forEach(letter => {
            if (letter.choose) {
                const block = document.querySelector(`#rebus-lettersBlock-letter-${letter.id}`);
                const sndBlock = document.querySelector(`#rebus-field-letter-${letter.position}`);
                block.style.cursor = 'default';
                this.animateAddLetter(block.firstChild, sndBlock);
                setTimeout(() => {block.firstChild.style.opacity = '1';}, 400);
                if (letter.itsTip) {
                    block.firstChild.classList.add('rebus-lettersBlock-letterTip');                        
                } else {
                    document.querySelector(`#rebus-field-letter-${letter.position}`).style.cursor = 'pointer';
                }
                // block.firstChild.style.transform = letter.currentStyle;  
            }
            });
        } else {
            if (this.currentTips) {
                this.appleTips();
            }
        }
    },
    data() {
    return {
        checkInProgress: false,
    }},
    props: {
        lettersArray: {
            tips: Array,
            default: []
        },
        answerArray: {
            tips: Array,
            default: []
        },
        gameType: {
            tips: String,
            default: '',
        },
        numLevel: {
            type: Number,
            default: 0,
        },
        haveCurrentState: {
            tips: Boolean,
            default: false
        },
        tipsArr: {
            tips: Array,
            default: []      
        },
        currentTips: {
            tips: Array,
            default: []    
        },
    },
    updated () {
        if (this.currentTips) {
            this.appleTips();
        }
    },
    computed: {
        answerPosition() {
            return this.answerArray.find(el => el.value === '');
        },
        userAnswer() {
            return this.answerArray.reduce((answer, el) => answer += el.value, '');
        },
    },
    methods: {
        ...mapActions(useUserStore, ['buyTip']),
        removeCheckInProgress() {
            this.checkInProgress = false;
        },
        clickLetter(letterInfo) {
            this.addLetter(letterInfo.block, letterInfo.item);
        },
        async buy(tip) {
            const response = await this.buyTip({
                'tip': tip.id,
                'answer': this.userAnswer,
            }, this.gameType);
            this.addTipLetter(response.tips_state[response.tips_state.length-1].data);
        },
        appleTips() {
            if (this.currentTips && this.currentTips.length>0) {
                this.currentTips.forEach(el => {if (el.t === 27 || el.t === 25) this.addTipLetter(el.data)})
            }
        },
        addTipLetter(tipLetter) {
            const answerItem = this.answerArray[tipLetter.index];
            if (answerItem.value) {
                const fieldBlock = document.querySelector(`#rebus-field-letter-${tipLetter.index}`);
                this.removeLetter(fieldBlock, answerItem);
            }

            let letterItem = this.lettersArray.find(el => el.value === tipLetter.letter && !el.choose);
            if (letterItem) {
                const letterBlock = document.querySelector(`#rebus-lettersBlock-letter-${letterItem.id}`);
                this.addLetter(letterBlock, letterItem, true, tipLetter.index);
            } else {
                letterItem = this.lettersArray.find(el => el.value === tipLetter.letter);
                const letterBlock = document.querySelector(`#rebus-lettersBlock-letter-${letterItem.id}`);
                const answerItemWrong = this.answerArray.find(el => el.position === letterItem.id);
                const fieldBlockWrong = document.querySelector(`#rebus-field-letter-${answerItemWrong.id}`);
                this.removeLetter(fieldBlockWrong, answerItemWrong);
                setTimeout(() => {
                this.addLetter(letterBlock, letterItem, true, tipLetter.index);
                }, 500);
            }      
        },  
        addLetter(block, item, itsTip = false, indexTip = false) {
            if (!item.choose) {
                const letterBlock = block.firstChild;
                let currentPosition;
                if (itsTip) {
                    currentPosition = this.answerArray[indexTip];
                } else {
                    currentPosition = this.answerArray.find(el => el.filled === false);
                }
                if (!currentPosition) {
                    return
                }
                const sndBlock = document.querySelector(`#rebus-field-letter-${currentPosition.id}`);
                currentPosition.filled = true;
                currentPosition.position = item.id;
                currentPosition.value = item.value;
                currentPosition.itsTip = itsTip;
                item.itsTip = itsTip;
                block.style.cursor = 'default';
                item.choose = true;
                item.position = currentPosition.id;
                item.currentStyle = this.animateAddLetter(letterBlock, sndBlock);
                if (itsTip) {
                    letterBlock.classList.add('rebus-lettersBlock-letterTip');
                } else {
                    sndBlock.style.cursor = 'pointer';
                };
                this.saveState();
                setTimeout(() => {
                letterBlock.style.opacity = '1';
                if (!this.answerPosition && !this.checkInProgress) {
                    this.checkInProgress = true;
                    this.check();
                }
                }, 400);
            }
        },
        animateAddLetter(fstBlock, sndBlock) {
            const fstBlockPosition = fstBlock.getBoundingClientRect();
            const sndBlockPosition = sndBlock.getBoundingClientRect();
            const left = fstBlock.parentNode.getBoundingClientRect().x - sndBlockPosition.x;
            const top = fstBlockPosition.y - sndBlockPosition.y;
            const styleTransfrom = `translate(${-left}px, ${-top}px) rotate(0)`;
            fstBlock.style.opacity = '0.8';
            fstBlock.style.transform = styleTransfrom;
            return styleTransfrom;
        },    
        removeLetter(block, item) {
            if (item.filled && !item.itsTip) {
                const itemLetter = this.lettersArray.find(el => el.id === item.position);
                const letterWrapBlock = document.querySelector(`#rebus-lettersBlock-letter-${item.position}`);
                const letterBlock = letterWrapBlock.firstChild;
                item.filled = false;
                item.position = '';
                item.value = '';
                itemLetter.choose = false;
                itemLetter.position = '';
                block.style.cursor = 'default';
                letterWrapBlock.style.cursor = 'pointer';
                this.animateRemoveLetter(letterBlock, itemLetter);
                this.saveState();
                setTimeout(() => {
                    letterBlock.style.opacity = '1';
                }, 400);
            }
        },     
        animateRemoveLetter(fstBlock, item) {
            fstBlock.style.opacity = '0.8';
            fstBlock.style.transform = `rotate(${item.rotate}deg)`;
        },   
        saveState() {
            this.$emit('saveState');
        },
        check() {
            this.$emit('check');
        },
    },
}

</script>
