<template>
  <div class="page-container page-container-wide">
    <div class="page-title">{{ $t("pages.main.pageTitle") }}</div>
    <div class="page-inner">
      <div class="controllPanel">
        <puzzlePanel :info="userInfo" :availableGames="availableGames" :dateUpdate="dateUpdate"></puzzlePanel>
        <themeSwitcher></themeSwitcher>
      </div>
      <div class="main-games-grid">
        <div class="game-grid-item" @click="gameStart('rebus')">
          <div class="game-title">Ребусы</div>
          <div class="game-image-container">
              <img v-if="darkTheme" src="@/assets/img/game-rebus-dark.svg" alt="Играть в ребусы">
              <img v-else src="@/assets/img/game-rebus.svg" alt="Играть в ребусы">
          </div>
        </div>
       <div class="game-grid-item" @click="gameStart('pic2')">
          <div class="game-title">Воображалка</div>
          <div class="game-image-container">
              <img v-if="darkTheme" src="@/assets/img/game-pic2-dark.svg" alt="Играть в воображалки">
              <img v-else src="@/assets/img/game-pic2.svg" alt="Играть в воображалки">
          </div>
        </div>
        <div class="game-grid-item" @click="gameStart('memorina')">
          <div class="game-title">Меморина</div>
          <div class="game-image-container">
              <img v-if="darkTheme" src="@/assets/img/game-memorina-dark.svg" alt="Играть в воображалки">
              <img v-else src="@/assets/img/game-memorina.svg" alt="Играть в воображалки">
          </div>
        </div>
        <div class="game-grid-item" @click="gameStart('colors')">
          <div class="game-title">Игры цвета</div>
          <div class="game-image-container">
              <img v-if="darkTheme" src="@/assets/img/game-lines-dark.svg" alt="Играть в Игры цвета">
              <img v-else src="@/assets/img/game-lines.svg" alt="Играть в Игры цвета">
          </div>
        </div>

      </div>
      <p class="main-announce main-announce-fix">Получай <span class="icon-diamond color-pink"></span> кристаллы и <span class="icon-star color-orange"></span> очки</p>
      <p class="main-announce">за любую успешно пройденную игру<br></p>



    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import Modal from '@/components/modals/Modal.vue'
import puzzlePanel from '@/components/home/puzzlePanel.vue'
import themeSwitcher from '@/components/home/themeSwitcher.vue'
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/store';
import { useUserStore } from '@/store/user';

export default {
  name: 'Nav',
  components: {
    RouterLink,
    RouterView,
    Modal,
    puzzlePanel,
    themeSwitcher
  },
  data() {
    return {
      announceModal: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ['darkTheme']),
    ...mapState(useUserStore, ['userInfo']),
    availableGames () {
      if (this.userInfo.games.summary) {
        return (this.userInfo.games.summary.available > 0);
      }
      return true;
    },
    dateUpdate() {
      return this.userInfo.newContentWillBePublishedAt;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['openSuspendModal']),
    modalClose() {
      this.announceModal = false
    },
    gameStart(game) {
      if (this.userInfo.is_suspended == 1) {
        this.openSuspendModal();
      }
      else
        this.$router.push({ name: game })
    }
  }
};
</script>
