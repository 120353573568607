<template>
    <div  @transitionend.stop="cardTransitionEnd()" @animationend="cardAnimationEnd()" @click="flipCard(card)" :class="['memorina__card', {'memorina__card--flip' : card.flip, 'memorina__card--tip' : card.itIsTip, 'memorina__card--matched' : card.matched, 'memorina__card--transition' : transitionCard}]">
        <div class="memorina__svg">
            <svg width="200" height="204" viewBox="0 0 200 204" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M74.8151 30.1561C68.8892 30.1561 64.1484 25.4257 64.1484 19.5126C64.1484 13.5996 68.8892 8.86914 74.8151 8.86914C80.741 8.86914 85.4818 13.5996 85.4818 19.5126C85.4818 25.4257 80.741 30.1561 74.8151 30.1561ZM74.8151 13.3039C71.2595 13.3039 68.5929 15.9648 68.5929 19.5126C68.5929 22.9126 71.4077 25.7213 74.8151 25.7213C78.2225 25.7213 81.0373 22.9126 81.0373 19.5126C81.0373 16.1126 78.2225 13.3039 74.8151 13.3039Z" fill="#4AADF5"/>
                <path d="M110.667 175.765C104.741 175.765 100 171.035 100 165.122C100 159.209 104.741 154.479 110.667 154.479C116.593 154.479 121.333 159.209 121.333 165.122C121.333 171.035 116.593 175.765 110.667 175.765ZM110.667 158.913C107.111 158.913 104.444 161.574 104.444 165.122C104.444 168.522 107.259 171.331 110.667 171.331C114.074 171.331 116.889 168.522 116.889 165.122C116.889 161.722 114.074 158.913 110.667 158.913Z" fill="#4AADF5"/>
                <path d="M170.667 87.3651C167.408 87.3651 164.741 84.7043 164.741 81.4521V81.3043C165.038 78.0521 167.556 75.5391 170.667 75.5391C173.926 75.5391 176.593 78.1999 176.593 81.4521C176.593 84.7043 173.926 87.3651 170.667 87.3651ZM169.186 81.5999C169.186 82.3391 169.778 82.9304 170.667 82.9304C171.556 82.9304 172.149 82.3391 172.149 81.4521C172.149 80.5651 171.556 79.9738 170.667 79.9738C169.926 79.9738 169.334 80.713 169.186 81.5999Z" fill="#4AADF5"/>
                <path d="M164.148 41.5391C158.074 41.5391 153.037 36.5131 153.037 30.4522C153.037 24.3913 158.074 19.3652 164.148 19.3652C170.222 19.3652 175.259 24.3913 175.259 30.4522C175.259 36.5131 170.222 41.5391 164.148 41.5391ZM164.148 23.8C160.445 23.8 157.482 26.7565 157.482 30.4522C157.482 34.1478 160.445 37.1044 164.148 37.1044C167.852 37.1044 170.815 34.1478 170.815 30.4522C170.815 26.7565 167.852 23.8 164.148 23.8Z" fill="#4AADF5"/>
                <path d="M164.148 23.8C160.445 23.8 157.482 26.7565 157.482 30.4522C157.482 34.1478 160.445 37.1044 164.148 37.1044C167.852 37.1044 170.815 34.1478 170.815 30.4522C170.815 26.7565 167.852 23.8 164.148 23.8Z" fill="#4AADF5"/>
                <path d="M164.148 180.495C161.778 180.495 160 178.721 160 176.356C159.852 175.321 160.296 174.286 161.037 173.547C161.778 172.66 162.963 172.217 164.148 172.217C166.519 172.217 168.296 173.991 168.296 176.356C168.296 178.721 166.519 180.495 164.148 180.495ZM164.148 173.695C163.407 173.695 162.667 173.991 162.074 174.582C161.778 174.878 161.333 175.469 161.482 176.356C161.482 177.982 162.667 179.017 164.148 179.017C165.63 179.017 166.815 177.834 166.815 176.356C166.815 174.878 165.63 173.695 164.148 173.695Z" fill="#4AADF5"/>
                <path d="M53.4819 62.5304L27.7041 59.7217L30.5189 34L56.2967 36.8087L53.4819 62.5304ZM32.593 55.8783L49.4819 57.6522L51.2597 40.8L34.3708 39.0261L32.593 55.8783Z" fill="#4AADF5"/>
                <path d="M32.593 55.8783L49.4819 57.6522L51.2597 40.8L34.3708 39.0261L32.593 55.8783Z" fill="#4AADF5"/>
                <path d="M41.333 195.573L10.8145 169.112L37.333 138.66L67.8515 165.121L41.333 195.573ZM17.0367 168.669L40.8885 189.217L61.6293 165.564L37.7774 144.869L17.0367 168.669Z" fill="#4AADF5"/>
                <path d="M139.556 124.912C146.37 128.312 158.37 124.173 161.778 117.373C158.37 124.173 162.519 136.147 169.333 139.547C162.519 136.147 150.519 140.286 147.111 147.086C150.519 140.138 146.519 128.312 139.556 124.912Z" fill="#4AADF5"/>
                <path d="M109.778 110.278L83.1113 79.3821L114.074 52.7734L140.741 83.6691L109.778 110.278ZM89.4817 79.8256L110.371 104.069L134.667 83.2256L113.63 58.9821L89.4817 79.8256Z" fill="#4AADF5"/>
                <path d="M70.074 125.209C69.037 125.209 68 125.061 66.9629 124.913C63.5555 124.026 60.7407 121.957 58.9629 119.148C57.1851 116.192 56.5926 112.792 57.4814 109.539C57.7777 108.357 57.7777 107.322 57.7777 106.287C57.6296 105.252 57.3333 104.218 56.7407 103.331C56.1481 102.296 55.4074 101.557 54.5185 100.818C53.4814 100.079 52.5926 99.6351 51.7037 99.4873C50.3703 99.1916 49.3333 99.1916 48.5926 99.3394H48.2963C47.4074 99.4873 46.3703 99.7829 45.3333 100.522C44.2963 101.113 43.5555 101.852 43.1111 102.592C42.3703 103.774 41.9259 104.661 41.7777 105.548L37.4814 104.513C37.7777 103.035 38.3703 101.705 39.4074 100.079C40.2963 98.7481 41.4814 97.5655 43.1111 96.5308C44.7407 95.496 46.2222 94.9047 47.7037 94.7568C49.1852 94.4612 50.8148 94.4612 52.7407 94.9047C54.3703 95.2003 55.8518 95.9394 57.3333 97.122C58.6666 98.1568 59.7037 99.3394 60.7407 100.818C61.6296 102.296 62.2222 103.922 62.3703 105.548C62.5185 107.174 62.3703 108.652 62.074 110.426C61.4814 112.644 61.7777 114.713 62.9629 116.635C64.1481 118.409 65.9259 119.739 68.1481 120.331C69.6296 120.626 72.1481 120.774 74.3703 119.444C76.2963 118.409 77.6296 116.487 78.074 114.418L82.3703 115.452C81.6296 118.705 79.4074 121.661 76.5926 123.287C74.6666 124.618 72.4444 125.209 70.074 125.209Z" fill="#4AADF5"/>
            </svg>
        </div>
        <div class="memorina__img">
        <img @load="loadingImg()"  :src="getImageUrl(card.url)" alt="">
        </div>
    </div> 
</template>

<script>
import { useUserStore } from '@/store/user';
import { mapActions, mapState } from 'pinia';


export default {
    name: 'memorinaCard',
    props: {
        card: {
            tips: Object,
            default: {url: 'https://tyapk.ru/storage/app/media/windows/psh-admin.png'},
        },
        transitionCard: {
            tips: Boolean,
            default: false,
        }
    },
    data () {
        return {
            loaded: false
        }
    },
    computed: {
        ...mapState(useUserStore, ['staticPrefix']),
    },
    methods: {
        cardTransitionEnd() {
            this.$emit('cardTransitionEnd');
        },
        cardAnimationEnd() {
            this.$emit('cardAnimationEnd');
        },
        loadingImg() {
            this.loaded = true;
            this.$emit('loadingImg');
        },
        flipCard() {
            this.$emit('clickCard', this.card);
        },
        getImageUrl(url) {
            return new URL(url, this.staticPrefix).href
        },

    },
}

</script>
