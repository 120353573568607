<template>
  <div class="page-container page-container--memorina">
    <div class="page-title">{{сurrentGameName}}</div>
    <div class="page-inner game-page-inner">
      <div class="page-inner-wrap">
        <div class="game-detail-top">
          <router-link class="btn btn-primary btn-medium btn-small btn-absolute" :to="{name: gameType}">
            <span class='btn--text'>
              <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 0.999756L2.34853 4.15123C1.8799 4.61986 1.8799 5.37965 2.34853 5.84828L5.5 8.99976" stroke="white" stroke-width="2" stroke-linecap="round"/>
              </svg>
              Назад
          </span>
          </router-link>
          <div class="game-difficulty-title">Уровень №{{ numLevel }}</div>
        </div>
        <div   class="memorina__timer">
          <img src="@/assets/icons/timer.svg" alt="Таймер">
          <vue-countdown v-if="showTimer" @progress="data => saveState(data)"  ref="countdown" :auto-start="false"  :time="amountOfSeconds*1000" v-slot="{ minutes, seconds }" :transform="transformSlotProps"  @end="onCountDownEnd()">
            <span :class="['memorina__timerValue', {'memorina__timer--beating': minutes == 0 && seconds < 6 && !inGame }]">{{minutes}}:{{ seconds }}</span>
          </vue-countdown>
          <span v-else :class="['memorina__timerValue']">0:00</span>
        </div>
        <div :class="['memorina__gameFieldWrap']">
          <div v-if="contentLoading || waitingServer" :class="['memorina__loader', {'memorina__loader--server' : waitingServer}]"><span></span></div>
          <div  @transitionend.stop="fieldTransitionEnd()" :class="['memorina__gameField', `memorina__gameField--${sizeField}`, {'memorina__countingEnd' : countingEnd, 'memorina__win' : winGameAnimation, 'memorina__inGame' : inGame, 'memorina__progress' : checkedProgress, 'memorina__loading' : contentLoading}]">
              <memorina-card v-for="card in arrayCards" :key="card.id"  @loadingImg="loadingImg" :card="card" @cardTransitionEnd="cardTransitionEnd" @cardAnimationEnd="cardAnimationEnd"  @clickCard="flipCardClick" :transitionCard="transitionCard"></memorina-card>
          </div>
        </div>

      </div>
        <tips :tips="tipsArr" ref='tipsBlock' @buy="buy" :loading="contentLoading || waitingServer" additionalClass="memorina__tips"></tips>
      </div>

    <Modal
          v-model="showWinModal"
          :title="'Поздравляем!'"
          @close="nextlevel"
          :class="['modal-wrapper game-win-modal', {'game-win-confetti': !prizePromo || prizePromo.length === 0}]"
        >
        <div class="buy-popup-text">
          Вы прошли уровень №{{ numLevel}}<br>
          Ваши призы:
        </div>
        <div :class="['game-rewards', {'game-rewards-twoitems': counPrizes === 2, 'game-rewards-big' : !prizePromo || prizePromo.length === 0, 'smallMarging' : (!prizePromo || prizePromo.length === 0) && !haveNextLevel}]" v-if="prizes">
          <div class="reward points" v-if="prizes && prizes.points">+{{prizes.points}}</div>
          <div class="reward diamonds" v-if="prizes && prizes.diamonds">+{{prizes.diamonds}}</div>
          <div class="reward puzzle" v-if="prizes && prizes.puzzle">+{{prizes.puzzle}}</div>
        </div>
      <div class="game-reward-promo" v-if="prizePromo">
        <promoItem v-for="item in prizePromo" :item="item" :gameMode="true"></promoItem>
      </div>
        <div v-if="!haveNextLevel">
          <p class="buy-popup-text">
            Вы прошли все доступные уровни!<br>
          </p>
          <p v-if="dateNewContent" class="game-rewards-update">
            Новые уровни будут {{dateNewContent}}
          </p>
        </div>
        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <RouterLink to="/" class="btn btn-secondary"><span class='btn--text'>Главное меню</span></RouterLink>
            <button v-if="haveNextLevel" class="btn btn-primary" @click="nextlevel()"><span class='btn--text'>Следующий уровень</span></button>
          </div>
        </template>

      </Modal>

    <fail-modal :showFailModal="showFailModal"  @restartLevel="restartLevel" @restartGame="restartGame"></fail-modal>

  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import { useMainStore } from '@/store';
import tips from '@/components/games/tips.vue';
import memorinaCard from '@/components/games/memorina/memorinaCard.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import winModal from '@/components/modals/winModal.vue';
import failModal from '@/components/modals/failModal.vue';
import promoItem from '@/components/promo/promoItem.vue';

export default {
  name: 'MemorinaGame',
  components: {
    RouterLink,
    RouterView,
    Modal,
    tips,
    memorinaCard,
    VueCountdown,
    winModal,
    promoItem,
    failModal,

  },
  data() {
    return {
      gameType: '',
      showFailModal: false,
      showWinModal: false,
      currentState: null,
      prizes: {
        diamonds: 0,
        points: 0,
        puzzle: 0,
      },
      prizePromo: [],
      contentLoading: true,
      currentTips: null,
      numLevel: '',
      сurrentGameName: 'Меморина',
      arrayCards: [],
      counting: true,
      flippedCards: [],
      checkedProgress: false,
      winGame: false,
      amountOfSeconds: 15,
      transitionCard: false,
      countingEnd: false,
      inGame: false,
      uploadedImages: 0,
      winGameAnimation: false,
      amountOfCardMemorina: 0,
      currentSeconds: 0,
      waitingServer: false,
      currentData: null,
      oldIdArray: [],
      showTimer: true,
      tipInProgress: false,
    };
  },
  async created() {
      await this.initGame();
  },
  computed: {
    ...mapState(useMainStore, ['darkTheme']),
    ...mapState(useUserStore, ['userInfo', 'games', 'staticPrefix', 'testSeconds']),
    gameInfo() {
      return this.games[this.gameType]
    },
    sizeField() {
      let size = '2x3'
      switch (this.amountOfCardMemorina) {
        case 3:
          size = '2x3'
          break;
        case 4:
          size = '2x4'
          break;
        case 6:
          size = '3x4'
          break;
        case 8:
          size = '4x4'
          break;
        case 10:
          size = '4x5'
          break;
        case 12:
          size = '4x6'
          break;
        case 14:
            size = '4x7'
            break;
          case 16:
            size = '4x8'
            break;
        default:
          size = '2x3'
      }
      return size;

    },
    counPrizes() {
      let count = 0;
      if (this.prizes.diamonds && this.prizes.diamonds > 0) ++count;
      if (this.prizes.points && this.prizes.points > 0) ++count;
      if (this.prizes.puzzle && this.prizes.puzzle > 0) ++count;
      return count;
    },
    unopenCardsCount () {
      return this.arrayCards.filter(el => !el.matched).length;
    },
    tipsArr(){
      let tips = [];
      if (this.gameInfo.tips.length > 0) {
        tips = this.gameInfo.tips
      }
      if (this.currentState && this.currentState.tipsArr) {
        tips = this.currentState.tipsArr;
      }
      tips.forEach(el => {
        el.disabled = this.unopenCardsCount < 5 || this.contentLoading || this.inGame;
      })
      return tips;
    },
    haveNextLevel() {
      if (this.userInfo.games[this.gameType].statsByLevels) {
        return this.userInfo.games[this.gameType].statsByLevels.month_levels_balance > 1;
      }
      return true;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['rebusGame', 'checkGame', 'getUserInfo', 'buyTip', 'rebusGameNew', 'rebusGameRestart']),
    loadingImg() {
      this.uploadedImages +=1;
      if (this.uploadedImages === this.amountOfCardMemorina*2) {
        this.contentLoading = false;
        this.inGame = true;
      }
    },
    async restartLevel() {
      this.transitionCard = false;
      this.showFailModal = false;
      this.uploadedImages = 0;
      this.contentLoading = true;
      let response = await this.rebusGameRestart(this.gameType);
      if (response) {
        if (response.redirect) {
          window.location.href = (response.redirect);
        } else {
          this.initGame();
          if (this.$refs.countdown) {
            this.$refs.countdown.restart();
            this.$refs.countdown.abort();
          }
          this.flippedCards = [];
          this.countingEnd = false;
          setTimeout(() => {
            this.inGame = true;
          }, 100);
        }
      }
    },
   async restartGame() {
      this.transitionCard = false;
      this.showFailModal = false;
      this.uploadedImages = 0;
      this.contentLoading = true;
      let response = await this.rebusGameNew(this.gameType);
      if (response) {
        if (response.redirect) {
          window.location.href = (response.redirect);
        } else {
          this.initGame();
          if (this.$refs.countdown) {
            this.$refs.countdown.restart();
            this.$refs.countdown.abort();
          }
          this.flippedCards = [];
          this.countingEnd = false;
          setTimeout(() => {
            this.inGame = true;
          }, 100);
        }
      }
    },
    resetArray() {
      this.arrayCards.forEach(el => {
        el.matched = false;
        el.itIsTip = false;
        el.flip = false;});
    },
    transformSlotProps(props) {
      const formattedProps = {};


        Object.entries(props).forEach(([key, value]) => {
          if (key === 'seconds') {
            formattedProps[key] = value < 10 ? `0${value}` : String(value);
          } else {
            formattedProps[key] = String(value);
          }
        });

      return formattedProps;
    },
        onCountDownEnd() {
          this.$refs.countdown.pause();
          this.$refs.tipsBlock.modalClose();
          localStorage.removeItem(`${this.gameType}CurrentState`);
          if (this.winGame) {
            return } else {
            this.transitionCard = true;
            this.check('wrong', true);
          }
        },
        fieldTransitionEnd() {
          if (this.countingEnd) {
            this.showFailModal = true;
          }
          if (this.winGame) {
            this.startGame();
          }
          if (this.inGame) {
            this.inGame = false;
            if (this.currentState && this.$refs.countdown) {
              this.$refs.countdown.start();
            }
          }
        },
        getArrayCards(arrayLinks = []) {
          const newArray = [];
          const listImg = this.shuffle(arrayLinks);
          let idArray = [];
          listImg.forEach((el, id) => {
            idArray = this.getId(el);
            newArray.push({
              url: el,
              flip: false,
              matched: false,
              itIsTip: false,
              id: idArray[0],
            });
            newArray.push({
              url: el,
              flip: false,
              matched: false,
              itIsTip: false,
              id: idArray[1],
            });
          });
        return this.shuffle(newArray);
    },
    getId(URL) {
      const currentUrl = URL.substring(URL.lastIndexOf('/')+1, URL.lastIndexOf('.'));
      let firstId = currentUrl + Math.random();
      while (this.oldIdArray.includes(firstId)) {
        firstId = currentUrl + Math.random();
      };
      let secondId = currentUrl + Math.random();
      while (firstId == secondId || this.oldIdArray.includes(firstId)) {
        secondId = currentUrl + Math.random();
      }
      return [firstId, secondId]

    },
    cardTransitionEnd(event) {
      if (this.checkedProgress && this.flippedCards.length > 1) {
        this.checkCards();
      } else {
        this.transitionCard = false;
      }
    },
    cardAnimationEnd() {
      this.tipInProgress = false;
      this.transitionCard = false;
    },
    flipCardClick(currentCard) {
      if (this.tipInProgress) {
        return;
      }
      this.flipCard(currentCard);
    },
    flipCard(currentCard, secondCard = null) {
        if (this.$refs.countdown) {
          this.$refs.countdown.start();
        }
      if (currentCard.flip || this.transitionCard || this.inGame) {
        return
      } else {
        currentCard.itIsTip = false;
        currentCard.flip = true;
        this.transitionCard = true;
        this.flippedCards.push(currentCard);
        if (secondCard) {
          secondCard.itIsTip = false;
          secondCard.flip = true;
          this.flippedCards.push(secondCard);
        }
      }
      if (this.flippedCards.length === 2) {
        this.checkedProgress = true;
      }
    },
    checkCards() {
      if (this.flippedCards[0].url === this.flippedCards[1].url) {
        this.flippedCards[0].matched = true;
        this.flippedCards[1].matched = true;
        this.checkedProgress = false;
      } else {
        this.flippedCards[0].flip = false;
        this.flippedCards[1].flip = false;
        this.checkedProgress = false;

      }
      this.flippedCards = [];
      this.saveState();
      this.checkWinGame();
    },
    checkWinGame() {
      if (this.arrayCards.find(el => !el.matched)) {
        return;
      } else {
        localStorage.removeItem(`${this.gameType}CurrentState`);
        this.currentState = null;
        this.$refs.countdown.pause();
        this.$refs.countdown.abort();
        this.winGame = true;
        this.showTimer = false;
        this.check('answer');
      }
    },
    shuffle(array) {
      let currentIndex = array.length;
      let temporaryValue;
      let randomIndex;

      while (currentIndex !== 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
      }
      return array;
    },
    nextlevel() {
      if (this.checkfinishedLevelTotal()) {
        this.$router.push({name: `${this.gameType}`});
      }
      if (this.winGameAnimation) {
        return
      }
      this.winGameAnimation = true;
      this.numLevel = this.numLevel + 1;
      this.showWinModal = false;

    },
    startLevel() {
      if (this.inGame) {
        this.inGame = false;
      }
    },
    async buy(tip) {
      if (this.unopenCardsCount < 5 || this.tipInProgress) {
        return;
      }
      this.tipInProgress = true;
      const response = await this.buyTip({
        'tip': tip.id,
        'answer' : 'answer'
      }, this.gameType);



        let tipCardFirst, tipCardSecond, filtredArray;
        tipCardFirst = this.arrayCards.find(el => !el.matched && !el.itIsTip && el.flip);
        if (tipCardFirst) {
          tipCardSecond = this.arrayCards.find(item => item !== tipCardFirst && item.url === tipCardFirst.url);
          this.flippedCards = [];
        } else {
          filtredArray = this.arrayCards.filter(el => !el.matched && !el.itIsTip);
          tipCardFirst = filtredArray[Math.floor(Math.random() * filtredArray.length)];
          tipCardSecond = filtredArray.find(item => item !== tipCardFirst && item.url === tipCardFirst.url);
        }
        tipCardFirst.itIsTip = true;
        tipCardSecond.itIsTip = true;
        if (this.$refs.countdown) {
          this.$refs.countdown.start();
        }
        this.saveState();
      setTimeout(() => {
        this.flipCard(tipCardSecond, tipCardFirst);}, 700);
    },
    async startGame(){
      this.winGameAnimation = false;
      this.winGame = false;
      this.contentLoading = true;
      this.uploadedImages = 0;
      let response = await this.rebusGame(this.gameType);
      if (response) {
      if (response.redirect)
        window.location.href = (response.redirect);
      else
        await this.initGame();
      }
      else{
        this.$router.push({name: `${this.gameType}`});
      }
    },
    getTimerFromCurrentState() {
      const currentData = this.currentState.currentTimer;
      if (currentData) {
      return  currentData.minutes*60  + currentData.seconds;
      } else {
        return this.gameInfo.state.time_limit;
      }
    },
    async initGame () {
      let currentTimer;
      let response;
      this.gameType = this.$route.meta.gameType;
      this.prizePromo = [];
      this.currentState = localStorage.getItem(`${this.gameType}CurrentState`);
      if (this.currentState) {
        this.currentState = JSON.parse(this.currentState);
      }

      if ((!this.currentState || (this.currentState && !this.currentState.currentTimer))  && this.gameInfo.content.id == null) {
        if (this.userInfo.games[this.gameType].lastGameResult === 'win') {
          response = await this.rebusGame(this.gameType);
        } else {
          response = await this.rebusGameRestart(this.gameType);
        }
        if (response.redirect) {
          window.location.href = (response.redirect);
        }
      }
      this.oldIdArray = [];
      currentTimer = this.currentState ? this.getTimerFromCurrentState() : this.gameInfo.state.time_limit;
      this.amountOfSeconds = currentTimer;
      this.showTimer = true;
      this.arrayCards = this.currentState ? this.currentState.arrayCards : this.getArrayCards(this.gameInfo.content.cards);
      this.arrayCards.forEach(el => this.oldIdArray.push(el.id));
      this.arrayCards.forEach(el =>  {
        el.flip = el.matched;
        if (el.itIsTip) {
          el.itIsTip = false;
          el.flip = true;
          el.matched = true;
        }
      });
      this.amountOfCardMemorina = this.currentState ? this.currentState.arrayCards.length/2 :  this.gameInfo.content.cards.length;
      if (this.$refs.countdown) {
        this.$refs.countdown.restart();
        this.$refs.countdown.abort();
      }
      if (!this.numLevel) {
        this.numLevel = this.currentState ? this.currentState.numLevel  : this.gameInfo.state.num;
      }
      if (this.currentState) {
        this.saveState();
      }
      this.tipInProgress = false;
    },
    checkfinishedLevelTotal() {
      return (this.userInfo.games[this.gameType].statsByLevels.available <= 0);
    },
    backToMain () {
      this.failModal = false;
      this.$router.push({name: `${this.gameType}`});
    },
    saveState(data) {
      if (data) {
        this.currentData = data
      }
      const currentState = {
        'arrayCards' : this.arrayCards,
        'numLevel' : this.numLevel,
        'flippedCards' : this.flippedCards,
        'startedAt': this.gameInfo.state.started_at,
        'timeLimit': this.gameInfo.state.time_limit,
        'tipsArr' : this.tipsArr,
        'currentTimer' : this.currentData
      };
      localStorage.setItem(`${this.gameType}CurrentState`, JSON.stringify(currentState));
    },
    failModalСonfirmClose() {
      this.restartLevel();
    },
    winModalСonfirmClose() {
      this.$router.push({name: 'home'});
      this.showWinModal = false;
    },
    closeModal() {
      this.checkModal = false;
    },
    async updateUser() {
      await this.getUserInfo();
    },
    async check(answer, needPopUp = false){
      this.waitingServer = true;
      let response = await this.checkGame({
         gameType: this.gameType,
        params: {
          answer: answer,
          isFinal: true,
        }
      });


      if (response && response.result){
        this.waitingServer = false;
        this.userInfo.games[this.gameType].qtyCompleted = response.qty;
        this.userInfo.games[this.gameType].qtySuccess = response.qty_success;
        this.userInfo.diamonds+= response.diamonds;
        this.userInfo.points+= response.points;
        this.userInfo.puzzle+= response.puzzleQty;
      }

       if (response && response.result == 'win'){
          this.showWinModal = true;
          if (response.points > 0 || response.puzzleQty > 0 || response.diamonds > 0){
           this.prizes = {};
           this.prizes.points = response.points;
            this.prizes.puzzle = response.puzzleQty;
             this.prizes.diamonds = response.diamonds;
           }

           if (response && response.prizeContent && response.prizeContent.length > 0){
            this.prizePromo = response.prizeContent
           }
         }
         if (response && response.result == 'fail' && needPopUp){
            this.countingEnd = true;
          }
         if (!response) {
            this.initGame();
         }
      },
  },
  beforeRouteLeave(to, from) {
    this.updateUser();
  },
};
</script>
