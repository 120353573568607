<template>
  <div class="page-container">
    <div class="page-title">{{сurrentGameName}}</div>
    <div class="page-inner game-page-inner" :class="{'content-loading' :  contentLoading}">
      <div class="page-inner-wrap">
        <div class="game-detail-top">
          <router-link class="btn btn-primary btn-medium btn-small btn-absolute" :to="{name: gameType}">
            <span class="btn--text">
                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 0.999756L2.34853 4.15123C1.8799 4.61986 1.8799 5.37965 2.34853 5.84828L5.5 8.99976" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
                Назад
            </span>
          </router-link>
          <div class="game-difficulty-title">Уровень №{{ numLevel }}</div>
          <button class="btn btn-primary btn-reset btn-absolute-right" @click="gameReset()" :class="{disabled: !answerHaveLetterNoTip}">
              <span class="btn--text">
                <svg width="24" height="26" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_120_2172)">
                    <path d="M12.9 1.94576C12.9002 1.75345 12.9558 1.56513 13.0605 1.4014C13.1651 1.23772 13.3144 1.10498 13.4919 1.01695C13.6693 0.928919 13.8683 0.888733 14.0676 0.900417C14.2668 0.9121 14.4593 0.975248 14.6242 1.0835L20.5335 4.96162L12.9 1.94576ZM12.9 1.94576V4.1455ZM12.9 1.94576V4.1455ZM12.9 4.1455C10.5429 4.16397 8.24161 4.83512 6.27968 6.07795C4.28996 7.33837 2.73833 9.13041 1.82185 11.228C0.905319 13.3258 0.665419 15.6344 1.13274 17.8617C1.60005 20.089 2.75337 22.1342 4.44595 23.7388C6.13848 25.3434 8.29426 26.4356 10.6404 26.8781C12.9865 27.3205 15.4184 27.0935 17.6288 26.2255C19.8391 25.3575 21.7292 23.8872 23.0594 21.9999C24.3896 20.1124 25.1 17.8928 25.1 15.6217C25.1 15.2582 24.9476 14.9107 24.6783 14.6553C24.4091 14.4001 24.0451 14.2577 23.6667 14.2577C23.2882 14.2577 22.9242 14.4001 22.6551 14.6553C22.3857 14.9107 22.2333 15.2582 22.2333 15.6217C22.2333 17.3508 21.6925 19.0415 20.6786 20.4801C19.6647 21.9188 18.223 23.0408 16.5352 23.7036C14.8473 24.3664 12.9898 24.5399 11.1977 24.202C9.40562 23.864 7.76017 23.0299 6.46917 21.806C5.17823 20.5821 4.29975 19.0235 3.94391 17.3275C3.58808 15.6315 3.77065 13.8735 4.46879 12.2756C5.16697 10.6776 6.34969 9.31093 7.86823 8.34898C9.3591 7.40456 11.1076 6.89216 12.9 6.87377V9.70591C12.9004 9.89811 12.9563 10.0863 13.061 10.2499C13.1657 10.4133 13.3151 10.5459 13.4925 10.6337C13.6699 10.7216 13.8688 10.7617 14.0679 10.7499C14.2671 10.7382 14.4594 10.6751 14.6242 10.5669L20.5335 6.68878L12.9 4.1455ZM20.8832 5.33645C20.8 5.18603 20.6799 5.0575 20.5335 4.96162V6.68878C20.6799 6.5929 20.8 6.46438 20.8832 6.31396C20.9665 6.16349 21.0101 5.99575 21.0101 5.8252C21.0101 5.65466 20.9665 5.48692 20.8832 5.33645Z" fill="white"/>
                    <path d="M20.5335 4.96162L12.9 1.94576V4.1455L20.5335 6.68878V4.96162Z" fill="white"/>
                    <path d="M12.9 1.94576C12.9002 1.75345 12.9558 1.56513 13.0605 1.4014C13.1651 1.23772 13.3144 1.10498 13.4919 1.01695C13.6693 0.928919 13.8683 0.888733 14.0676 0.900417C14.2668 0.9121 14.4593 0.975248 14.6242 1.0835L20.5335 4.96162M12.9 1.94576L20.5335 4.96162M12.9 1.94576V4.1455M20.5335 4.96162C20.6799 5.0575 20.8 5.18603 20.8832 5.33645C20.9665 5.48692 21.0101 5.65466 21.0101 5.8252C21.0101 5.99575 20.9665 6.16349 20.8832 6.31396C20.8 6.46438 20.6799 6.5929 20.5335 6.68878M20.5335 4.96162V6.68878M12.9 4.1455C10.5429 4.16397 8.24161 4.83512 6.27968 6.07795C4.28996 7.33837 2.73833 9.13041 1.82185 11.228C0.905319 13.3258 0.665419 15.6344 1.13274 17.8617C1.60005 20.089 2.75337 22.1342 4.44595 23.7388C6.13848 25.3434 8.29426 26.4356 10.6404 26.8781C12.9865 27.3205 15.4184 27.0935 17.6288 26.2255C19.8391 25.3575 21.7292 23.8872 23.0594 21.9999C24.3896 20.1124 25.1 17.8928 25.1 15.6217C25.1 15.2582 24.9476 14.9107 24.6783 14.6553C24.4091 14.4001 24.0451 14.2577 23.6667 14.2577C23.2882 14.2577 22.9242 14.4001 22.6551 14.6553C22.3857 14.9107 22.2333 15.2582 22.2333 15.6217C22.2333 17.3508 21.6925 19.0415 20.6786 20.4801C19.6647 21.9188 18.223 23.0408 16.5352 23.7036C14.8473 24.3664 12.9898 24.5399 11.1977 24.202C9.40562 23.864 7.76017 23.0299 6.46917 21.806C5.17823 20.5821 4.29975 19.0235 3.94391 17.3275C3.58808 15.6315 3.77065 13.8735 4.46879 12.2756C5.16697 10.6776 6.34969 9.31093 7.86823 8.34898C9.3591 7.40456 11.1076 6.89216 12.9 6.87377V9.70591C12.9004 9.89811 12.9563 10.0863 13.061 10.2499C13.1657 10.4133 13.3151 10.5459 13.4925 10.6337C13.6699 10.7216 13.8688 10.7617 14.0679 10.7499C14.2671 10.7382 14.4594 10.6751 14.6242 10.5669L20.5335 6.68878M12.9 4.1455L20.5335 6.68878" stroke="white" stroke-width="0.2"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_120_2172">
                      <rect width="26" height="28" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
            </span>
          </button>
        </div>
        <imgField :preloadImgArray=preloadImgArray :gameType="gameType" :imgSRC="imgSRC" :imgArray="imgArray" @rebusLoad="rebusLoad" @pic2LoadImg="pic2TipLoadImg" @openInfoPopUp="openInfoPopUp"></imgField>
        <lettersField  :lettersArray="lettersArray" ref="lettersBlock" @saveState="saveState"  :answerArray="answerArray" :currentTips="currentTips" :haveCurrentState="!!currentState"  :gameType="gameType"  @check="check"></lettersField>
        <tips :tips="tipsArr" @buy="buy" :loading="contentLoading"></tips>


        <Modal
            v-model="failModal"
            :title="userAnswer"
            @close="failModalСonfirmClose"
            class="modal-wrapper game-lose-modal"
        >
          <div class="buy-popup-text">
            Это неправильный ответ
          </div>
          <div class="buy-popup-reward">
            <img v-if="darkTheme" src="@/assets/img/lose-dark.svg" class="game-fail-img" alt="fail" />
            <img v-else src="@/assets/img/lose-light.svg" class="game-fail-img" alt="fail" />
          </div>

          <template #customFooter>
            <div class="modal-buttons-large mt-0">
              <RouterLink to="/" class="btn btn-secondary"><span class='btn--text'>Главное меню</span></RouterLink>
              <div @click="restart()" class="btn btn-primary"><span class='btn--text'>Начать заново</span></div>
            </div>
          </template>

        </Modal>

        <infoImgPopUp :currentImg="currentImg" :currentImgSource="currentImgSource" :currentImgAuthor="currentImgAuthor" :showinfoImgPopUp="showinfoImgPopUp" @closeInfoImgPopUp="closeInfoImgPopUp"></infoImgPopUp>

        <Modal
            v-model="winModal"
            :title="'Поздравляем!'"
            @close="winModalСonfirmClose"
            :class="['modal-wrapper game-win-modal', {'game-win-confetti': !prizePromo || prizePromo.length === 0}]"
          >
          <div class="buy-popup-text">
            Вы прошли уровень №{{ numLevel}}<br>
            Ваши призы:
          </div>
          <div :class="['game-rewards', {'game-rewards-twoitems': counPrizes === 2, 'game-rewards-big' : !prizePromo || prizePromo.length === 0, 'smallMarging' : (!prizePromo || prizePromo.length === 0) && !haveNextLevel}]" v-if="prizes">
            <div class="reward points" v-if="prizes.points">+{{prizes.points}}</div>
            <div class="reward diamonds" v-if="prizes.diamonds">+{{prizes.diamonds}}</div>
            <div class="reward puzzle" v-if="prizes.puzzle">+{{prizes.puzzle}}</div>
          </div>
          <div class="game-reward-promo" v-if="prizePromo">
            <promoItem v-for="item in prizePromo" :item="item" :gameMode="true"></promoItem>
          </div>
          <div v-if="!haveNextLevel">
            <p class="buy-popup-text">
              Вы прошли все доступные уровни!<br>
            </p>
            <p class="game-rewards-update">
              Новые уровни будут {{dateNewContent}}
            </p>
          </div>
          <template #customFooter>
            <div class="modal-buttons-large mt-0">
              <RouterLink to="/" class="btn btn-secondary"><span class="btn--text">Главное меню</span></RouterLink>
              <button v-if="haveNextLevel" class="btn btn-primary" @click="nextlevel()"><span class="btn--text">Следующий уровень</span></button>
            </div>
          </template>

        </Modal>

      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import tips from '@/components/games/tips.vue'
import lettersField from '@/components/games/rebus/lettersField.vue'
import Modal from '@/components/modals/Modal.vue'
import { useMainStore } from '@/store';
import promoItem from '@/components/promo/promoItem.vue';
import infoImgPopUp from '@/components/games/pic2/infoImgPopUp.vue';
import imgField from '@/components/games/rebus/imgField.vue';

export default {
  name: 'RebusGame',
  components: {
    RouterLink,
    RouterView,
    tips,
    lettersField,
    Modal,
    promoItem,
    infoImgPopUp,
    imgField
  },
  data() {
    return {
      imgSRC: '',
      gameType: '',
      failModal: false,
      winModal: false,
      currentState: null,
      prizes: {
        diamonds: 0,
        points: 0,
        puzzle: 0,
      },
      prizePromo: [],
      contentLoading: true,
      answerArray: [],
      imgArray: [],
      lettersArray: [],
      userAnswerModal: '',
      currentTips: null,
      numLevel: '',
      pic2TipImg: '',
      infoPopUp: false,
      currentImgAuthor: '',
      currentImgSource: '',
      currentImg: '',
      showinfoImgPopUp: false,
      pic2LoadedImg: 0,
      tipInProgress: false,
      firstInit: true,
      preloadImgArray: [],
      testCurrentImg: '',
    };
  },
  async created() {
    await this.initGame();
  },
  mounted() {
    if (  this.answerEmptyLength === 0) {
      this.gameReset();
    }
  },
  computed: {
    ...mapState(useMainStore, ['darkTheme']),
    ...mapState(useUserStore, ['userInfo', 'games', 'staticPrefix']),
    gameInfo() {
      return this.games[this.gameType]
    },
    сurrentGameName() {
      const gamesName = {
        rebus: 'Ребусы',
        memorina: 'Меморина',
        pic2: 'Воображалка',
        colors: 'Игры цвета',
        default: '',
      }
      return gamesName[this.gameType] ? gamesName[this.gameType] : gamesName.default;
    },
    dateNewContent() {
      if (this.userInfo.newContentWillBePublishedAt) {
        const datePublished = new Date(this.userInfo.newContentWillBePublishedAt);
        let dd = datePublished.getDate();
        dd = dd < 10 ? `0${dd}` : dd;

        let mm = datePublished.getMonth() + 1;
        mm = mm < 10 ? `0${mm}` : mm;

        let yy = datePublished.getFullYear();

        return dd + '.' + mm + '.' + yy;
      }
      return '';
    },
    counPrizes() {
      let count = 0;
      if (this.prizes.diamonds && this.prizes.diamonds > 0) ++count;
      if (this.prizes.points && this.prizes.points > 0) ++count;
      if (this.prizes.puzzle && this.prizes.puzzle > 0) ++count;
      return count;
    },
    answerPosition() {
        return this.answerArray.find(el => el.value === '');
    },
    answerEmptyLength() {
      return this.answerArray.filter(el => el.value === '').length;
    },
    userAnswer() {
     return this.answerArray.reduce((answer, el) => el.value === '' ? answer+='#' : answer += el.value, '');
    },
    tipsArr(){
      let tips = [];
      if (this.gameInfo.tips.length > 0) {
        tips = this.gameInfo.tips;
      }
      if (this.currentState && this.currentState.tipsArr) {
        tips = this.currentState.tipsArr;
      }
      tips.forEach(el => el.disabled = this.checkDisabledTips(el));
        return tips;
    },
    answerHaveLetterNoTip() {
      return this.answerArray.find(el => el.value !== '' && !el.itsTip);
    },
    monthLevelBalance() {
      if (this.userInfo.games[this.gameType].statsByLevels) {
        return this.userInfo.games[this.gameType].statsByLevels.month_levels_balance;
      }
    },
    haveNextLevel() {
      if (this.userInfo.games[this.gameType].statsByLevels) {
        return this.userInfo.games[this.gameType].statsByLevels.month_levels_balance > 1;
      }
      return true;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['rebusGame', 'checkGame', 'getUserInfo', 'buyTip', 'rebusGameNew']),
    checkDisabledTips(tip) {
      let status = false;
      if ((tip.id === 25 || tip.id === 27) && this.answerEmptyLength < 2) {
        status = true;
      }
      if (tip.id === 28 && this.imgArray.length > 2) {
        status = true;
      }
      return status;
    },
    getImgWithTip() {
      const newImgArray = this.gameInfo.content.images;
      if (this.currentTips && this.currentTips.length>0) {
          this.currentTips.forEach(el => {if (el.t === 28) newImgArray.push(el.data.image)})
      }
      return newImgArray;
    },
    openInfoPopUp(id) {
      if (this.gameInfo.content.authors) {
        this.currentImgAuthor = this.gameInfo.content.authors[`img${id+1}`];
      }

      if (this.gameInfo.content.sources) {
        this.currentImgSource = this.gameInfo.content.sources[`img${id+1}`];
      }
      this.currentImg = this.getImageUrl(this.imgArray[id]);
      this.showinfoImgPopUp = true;
    },
    closeInfoImgPopUp() {
      this.showinfoImgPopUp = false;
      this.currentImgAuthor = '';
      this.currentImgSource = '';
      this.currentImg = '';
    },
    getlettersArray(currentArray) {
      return currentArray.map((el, index) => {
        return {
          value: el,
          position: '',
          id: index,
          choose: false,
          currentStyle: '',
          itsTip: false,
          top: this.getShift(),
          rotate: this.getShift()
        }
      })
    },
    getImageUrl(url) {
      return new URL(url, this.staticPrefix).href
    },
    getAnswerArray(currentArray) {
      if (currentArray) {
        return currentArray.map((el, index) => {
          return {
            value: '',
            id: index,
            filled: false,
            itsTip: false,
            position: null,
            opacity: 1,
          }
        })
      }
    },
    async buy(tip) {
      this.contentLoading = true;
      const response = await this.buyTip({
        'tip': tip.id,
        'answer': this.userAnswer,
      }, this.gameType);
      if (tip.id === 27 || tip.id === 25) {
        this.contentLoading = false;
        this.$refs.lettersBlock.addTipLetter(response.tips_state[response.tips_state.length-1].data);
      }

      if (tip.id === 28) {
        this.tipInProgress = true;
        this.imgArray.push(response.tips_state[response.tips_state.length-1].data.image);
        const tipImg = new Image();
        tipImg.onload = () => this.pic2TipLoadImg();
        this.saveState();
      }

    },
    getShift() {
      let shift =  Math.random() * 4;
      const position = Math.random() > 0.5 ? 1 : -1;
      shift *= position;
      return shift;
    },
    gameReset() {
      this.$refs.lettersBlock.removeCheckInProgress();
      this.answerArray.forEach(el => {
        if (el.filled && !el.itsTip) {
          const fieldBlockWrong = document.querySelector(`#rebus-field-letter-${el.id}`);
          this.$refs.lettersBlock.removeLetter(fieldBlockWrong, el);
        }
      });
    },
    nextlevel() {
      if (this.contentLoading) {
        return;
      }
      this.contentLoading = true;
      this.getUserInfo();
      this.winModal = false;
      localStorage.removeItem(`${this.gameType}CurrentState`);
      if (this.haveNextLevel) {
        this.$refs.lettersBlock.removeCheckInProgress();
        this.startGame();
      } else {
        this.$router.push({name: `${this.gameType}`});
      }
    },
    checkfinishedLevelTotal() {
      return (this.userInfo.games[this.gameType].statsByLevels.available <= 0);
    },
    async startGame(){
      if (this.checkfinishedLevelTotal()) {
        this.$router.push({name: `${this.gameType}`});
      }
      let response = await this.rebusGameNew(this.gameType);
      if (response){
      if (response.redirect)
        window.location.href = (response.redirect);
      else {
        this.firstInit = false;
        await this.initGame();
      }
      }
      else{
        this.$router.push({name: `${this.gameType}`});
      }
    },
    async initGame () {
      this.gameType = this.$route.meta.gameType;
      this.prizePromo = [];
      this.currentState = localStorage.getItem(`${this.gameType}CurrentState`);
      if (this.currentState) {
        this.currentState = JSON.parse(this.currentState);
      }


    if (!this.currentState && this.gameInfo.content.id == null) {
      let response = await this.rebusGame(this.gameType);
      if (response.redirect) {
        window.location.href = (response.redirect);
      }
    }
    if (this.firstInit) {
      this.currentTips = this.currentState ? this.currentState.currentTips : this.gameInfo.state.tips;
      this.answerArray = this.currentState ? this.currentState.answerArray : this.getAnswerArray(this.gameInfo.content.size);
      this.lettersArray = this.currentState ? this.currentState.lettersArray :  this.getlettersArray(this.gameInfo.content.letters);
      this.numLevel = this.currentState ? this.currentState.numLevel  : this.gameInfo.num;

    }
    if (this.gameType === 'rebus') {
      this.imgSRC = this.currentState ? this.currentState.imgSRC : this.gameInfo.content.image;
    }
    if (this.gameType === 'pic2') {
      this.pic2LoadedImg = 0;
      this.preloadImgArray = this.currentState ? this.currentState.imgArray : this.getImgWithTip();
      this.preloadImgArray.forEach(imgItem => {
        const img = new Image();
        img.src = this.getImageUrl(imgItem);
        img.onload = () => this.pic2LoadImg();
      });
      this.testCurrentImg = this.preloadImgArray[0];
    }

    },
    pic2TipLoadImg() {
      if (this.tipInProgress) {
        this.tipInProgress = false;
        this.contentLoading = false;
      }
    },
    pic2LoadImg() {
      this.pic2LoadedImg += 1;
      if (this.pic2LoadedImg === this.preloadImgArray.length)  {
        this.contentLoading = false;
        if (!this.firstInit) {
          this.currentTips = this.currentState ? this.currentState.currentTips : this.gameInfo.state.tips;
          this.answerArray = this.currentState ? this.currentState.answerArray : this.getAnswerArray(this.gameInfo.content.size);
          this.lettersArray = this.currentState ? this.currentState.lettersArray :  this.getlettersArray(this.gameInfo.content.letters);
          this.numLevel = this.currentState ? this.currentState.numLevel  : this.gameInfo.num;
          if (this.imgArray.length === 3) {
          this.imgArray.pop();
        }
        }
        this.preloadImgArray.forEach((el, index) => {
          this.imgArray[index] = el;
        });
      }
    },
    rebusLoad() {
      this.contentLoading = false;
      if (!this.firstInit) {
        this.currentTips = this.currentState ? this.currentState.currentTips : this.gameInfo.state.tips;
        this.answerArray = this.currentState ? this.currentState.answerArray : this.getAnswerArray(this.gameInfo.content.size);
        this.lettersArray = this.currentState ? this.currentState.lettersArray :  this.getlettersArray(this.gameInfo.content.letters);
        this.numLevel = this.currentState ? this.currentState.numLevel  : this.gameInfo.num;
      }
    },
    backToMain () {
      this.gameReset();
      this.failModal = false;
      this.$router.push({name: `${this.gameType}`});
    },
    saveState() {
      const currentState = {
        answerArray : this.answerArray,
        lettersArray : this.lettersArray,
        imgSRC: this.imgSRC,
        imgArray: this.imgArray,
        tipsArr: this.tipsArr,
        currentTips: this.currentTips,
        numLevel: this.numLevel,
      };
      localStorage.setItem(`${this.gameType}CurrentState`, JSON.stringify(currentState));
    },
    failModalСonfirmClose() {
      this.updateUser();
      this.restart();
      this.failModal = false;
    },
    winModalСonfirmClose() {
      this.updateUser();
      if (this.haveNextLevel) {
        this.nextlevel();
      } else {
        this.$router.push({name: 'home'});
      }
      this.winModal = false;
    },
    closeModal() {
      this.checkModal = false;
    },
    restart() {
      this.failModal = false;
      this.gameReset();
    },
    async updateUser() {
      await this.getUserInfo();
    },
    async check(){
      if(this.contentLoading) {
        return;
      }
      this.contentLoading = true;
      let response = await this.checkGame({
        gameType: this.gameType,
        params: {
          answer: this.userAnswer,
          isFinal: true,
        }
      });

        if (response && response.result){
          this.userInfo.games[this.gameType].qtyCompleted = response.qty;
          this.userInfo.games[this.gameType].qtySuccess = response.qty_success;
          this.userInfo.diamonds+= response.diamonds;
          this.userInfo.points+= response.points;
          this.userInfo.puzzle+= response.puzzleQty;
        }

        if (!response.result || response.result === 'fail'){
          this.userAnswerModal = this.userAnswer;
          this.failModal = true;
        }
        if (response.result == 'win'){
          localStorage.removeItem(`${this.gameType}CurrentState`);
          this.winModal = true;
          if (response.points > 0 || response.puzzleQty > 0 || response.diamonds > 0){
            this.prizes = {};
            this.prizes.points = response.points;
            this.prizes.puzzle = response.puzzleQty;
            this.prizes.diamonds = response.diamonds;
          }

          if (response.prizeContent && response.prizeContent.length > 0){
            this.prizePromo = response.prizeContent
          }
          else if (response.qtyGamesForNextPromocode && response.qtyGamesForNextPromocode > 0) {
            this.prizePromoHolder = `Побед до следующего промокода: ${response.qtyGamesForNextPromocode}`
          }
          else{
            this.prizePromoHolder = 'На сегодня все промокоды разыграны';
          }
        }
        this.contentLoading = false;
      },
  },
  beforeRouteLeave(to, from) {
    this.getUserInfo();
  },
};
</script>
