<template>

    <Modal
        v-model="showModal"
        @close="confirmClose"
        class="modal-wrapper game-win-modal"
        btnText="Закрыть"
        @confirm="confirmClose"
    >
        <img :src="currentImg" :class="['imaginaryPopup-img']" alt="">
        <div v-if="currentImgSource">
            <p class="imaginaryPopup-title">Источник:</p>
            <p a class="imaginaryPopup-text"><a :href="currentImgSource" target="_blank">{{currentImgSource}}</a></p>
        </div>
        <div v-if="currentImgAuthor">
            <p class="imaginaryPopup-title">Автор:</p>
            <p class="imaginaryPopup-text">{{currentImgAuthor}}</p>
        </div>


      </Modal>

</template>


<script>
  import Modal from '@/components/modals/Modal.vue'

  export default {
    name: 'infoImgPopUp',
    props: {
        currentImg: {
            type: String,
            default: ''
        },
        currentImgSource: {
            type: String,
            default: ''
        },
        currentImgAuthor: {
            type: String,
            default: '',
        },
        showinfoImgPopUp: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        showModal () {
            return this.showinfoImgPopUp
        }
    },
    components: {
     Modal
    },
    methods: {
        confirmClose () {
            this.$emit('closeInfoImgPopUp');
        }
    }
  };
</script>
