import { defineStore } from 'pinia';
import Http from '@/Http';

const progressDelays = ['2000', '5000', '10000', '15000', '20000', '25000', '600000'];
let progressCounter = -1, progressTimneout;
const staticPrefix = process.env.VUE_APP_URL;
const authSuff = process.env.VUE_APP_AUTH_URL_PROFILE ? process.env.VUE_APP_AUTH_URL_PROFILE : '';
const devMode = process.env.VUE_APP_URL === 'http://razgadayka.devel.via-mobi.com' ? true : false;

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {
      bonus_given: false,
      dataLoaded: false,
      phone: '+7 999 999-99-98',
      diamonds: '0',
      points: '0',
      promocodeMonthly: null,
      puzzle:
        {
          openedItems: [],
          qty: 25
        },
        games: {
          pic2: {
            qtyCompleted: 0,
            qtySuccess: 0,
          },
          rebus: {
            qtyCompleted: 0,
            qtySuccess: 0,
          },
          memorina: {
            qtyCompleted: 0,
            qtySuccess: 0,
          },
          colors: {
            qtyCompleted: 0,
            qtySuccess: 0,
          }
        },
    },
    staticPrefix: process.env.VUE_APP_URL,
    isDevMode: devMode,
    promo: [],
    shop: [],
    games: {
      rebus: {
        qtyCompleted: 0,
        qtySuccess: 0,
        content: {
          id: null
        },
        state: {
          tips: []
        },
        tips: []
      },
      pic2: {
        qtyCompleted: 0,
        qtySuccess: 0,
        content: {
          id: null
        },
        state: {
          tips: []
        },
        tips: []
      },
      colors: {
        content: {
          id: '',
          sequence: []
        },
        num: 7,
        level: 1,
        state: {
          attempts: 0,
          tips: []
        },
        tips: []
      },
      memorina: {
        qtyCompleted: 0,
        qtySuccess: 0,
        content: {
          cards: [],
          id: null
        },
        state: {
          tips: [],
          time_limit: 0,
          started_at: 0,
        },
        tips: []
      },
    },
    phone: '+7 999 999-99-99',
    puzzle: {
      total: 25,
      grid: [2,6,10],
      completed: false,
    },
    storeBackUrl: '',
    serviceMode: false,
    winners: {},
    amountOfCardMemorina: 0,
    testSeconds : 0,
  }),
  actions: {
    changeAmountOfCardMemorina (amount = 6) {
      this.amountOfCardMemorina = amount;
    },
    changeTestSeconds(seconds = 0) {
      this.testSeconds = seconds;
    },
    checkDaily() {
      return this.userInfo.bonus_given
    },
    async takeDaily() {
      this.userInfo.bonus_given = false;
    },
    setBackUrl(url) {
      this.storeBackUrl = url;
    },
    async getWinners() {
      const result = await Http.get('/winners');
      if (result) {
        this.winners = result;
      }
      return result;
    },
    async getUserInfo() {
      const result = await Http.get('/profile');
      if (result) {
        this.userInfo = result;
        this.dataLoaded = true;
      }
    },
    async checkProgress(data) {
      if (progressCounter < progressDelays.length){
        const result = await Http.post('/store-check', data);
        if (result.data.inProgress) {
          progressCounter++;
          await new Promise(resolve => setTimeout(resolve, progressDelays[progressCounter]));
          await this.checkProgress(data);
        }
        else if(result.data.isSuccess){
          this.userInfo.diamonds = result.data.userDiamonds;
        }
      }
    },
    async rebusGame(data) {
      const result = await Http.get(`/game/${data}/start`);
      if (result && !result.redirect) {
        this.games[data] = result;
      }
      return result;
    },
    async rebusGameRestart(data) {
      const result = await Http.get(`/game/${data}/start?restart`);
      if (result && !result.redirect) {
        this.games[data] = result;
      }
      return result;
    },
    async rebusGameNew(data) {
      const result = await Http.get(`/game/${data}/start-new`);
      if (result && !result.redirect) {
        this.games[data] = result;
      }
      return result;
    },
    async checkGame(data) {
      const result = await Http.post(`/game/${data.gameType}/check`, data.params);
      if (result) {
        return result;
      }
    },
    async getPromo() {
      const result = await Http.get('/profile/promocodes');
      if (result) {
        this.promo= result;
      }
    },
    async getShop() {
      const result = await Http.get('/store');
      if (result) {
        this.shop=result;
      }
    },
    async buyDiamods(data) {
      const result = await Http.post('/store', data);
      if (result) {
        if (result.data.isSuccess)
          this.userInfo.diamonds = result.data.userDiamonds
          this.userInfo.points = result.data.userPoints

        return result
      }
    },
    async buyTip(data, gameType) {
      const result = await Http.post(`/game/${gameType}/tip`, data);
      if (result) {
        if (result.userDiamonds)
          this.userInfo.diamonds = result.userDiamonds;

        this.games[gameType].tips.forEach((el) => {
            el.status = result.tips[el.id]
        });
        return result;
      }
    },
    async updateLinesAttempts(num) {
      this.games.colors.state.attempts = num;
    },
    async updateLinesLevel(num) {
      this.games.colors.state.step = num;
    },
    async colorsGame(data) {
      const result = await Http.get(`/game/${data}/start`);
      if (result && !result.redirect) {
        this.games[data] = result;
      }
      return result;
    },
    async colorsGameNew(data) {
      const result = await Http.get(`/game/${data}/start-new`);
      if (result && !result.redirect) {
        this.games[data] = result;
      }
      return result;
    },
  },
});
