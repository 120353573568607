<template>
<div>

  <div :class="['rebus-tips-wrap', additionalClass]">
    <div v-for="tip in tips" class="rebus-tip">
      <button class="btn btn-primary btn-clue" @click="tipModal(tip)">
        <span class="btn--text">
        <span :class="['rebus-btn-disabled', {'rebus-btn-disabled--hide' : tip.disabled}]">
          <svg width="47" height="42" viewBox="0 0 47 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7.75385L9.65754 0L23.8219 16.4943L37.9863 0L47 7.75385L29.921 22.6154L41.8493 36.5251L40.5616 42L23.5 28.5L8.36987 42L5.15068 36.5251L17.7261 22.6154L0 7.75385Z" fill="#959595"/>
          </svg>
        </span>
        <svg v-if="tip.id === 25 || tip.id === 27" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.00250489 14.6501C-0.0337703 16.5827 0.324293 18.5022 1.05444 20.2892C1.78458 22.0763 2.8712 23.6927 4.24676 25.038C5.57707 26.4253 7.17493 27.523 8.94179 28.2633C10.7087 29.0036 12.6071 29.3709 14.52 29.3425C17.0794 29.3789 19.6002 28.7107 21.8114 27.4099L28.3871 33.9424C28.9414 33.4593 29.7216 33.1784 30.2411 32.6513C31.5888 31.2956 32.7988 29.8121 33.8387 28.2038L27.2963 21.6998C28.4705 19.5411 29.0704 17.1123 29.0376 14.6501C29.0695 12.7233 28.7115 10.8102 27.9855 9.02788C27.2595 7.24551 26.1807 5.63137 24.8151 4.2842C23.4818 2.90232 21.8829 1.8097 20.1164 1.0733C18.3499 0.336908 16.4529 -0.0277531 14.5418 0.00166677C12.6274 -0.0279165 10.7272 0.336582 8.95708 1.0729C7.18701 1.80922 5.58427 2.9019 4.24676 4.2842C2.87949 5.63121 1.79813 7.24484 1.06849 9.02689C0.338837 10.8089 -0.0238463 12.7222 0.00250489 14.6501ZM7.16332 7.22707C8.11781 6.2366 9.26255 5.45306 10.5275 4.9244C11.7924 4.39573 13.151 4.13305 14.52 4.15243C15.8858 4.13295 17.2412 4.39567 18.5026 4.92443C19.764 5.4532 20.9049 6.23681 21.855 7.22707C22.8349 8.18683 23.6106 9.33818 24.1345 10.6107C24.6584 11.8833 24.9196 13.2503 24.9021 14.6282V14.6501C24.9237 16.3965 24.5042 18.1198 23.6833 19.6574C23.1892 20.5261 22.5815 21.3239 21.8767 22.0292C21.2564 22.6915 20.5454 23.2609 19.7655 23.7203C18.1849 24.6633 16.3778 25.1495 14.5418 25.1258C13.1708 25.1455 11.8103 24.8808 10.5449 24.3481C9.27938 23.8155 8.13583 23.0262 7.18509 22.0292C6.20201 21.0765 5.42407 19.9295 4.8998 18.6599C4.37553 17.3903 4.11618 16.0253 4.13793 14.6501V14.6282C4.1176 13.2519 4.37537 11.8858 4.89552 10.6133C5.41568 9.34089 6.1873 8.1887 7.16332 7.22707Z" fill="#31BAFF"/>
          <path d="M0.00250489 14.6501C-0.0337703 16.5827 0.324293 18.5022 1.05444 20.2892C1.78458 22.0763 2.8712 23.6927 4.24676 25.038C5.57707 26.4253 7.17493 27.523 8.94179 28.2633C10.7087 29.0036 12.6071 29.3709 14.52 29.3425C17.0794 29.3789 19.6002 28.7107 21.8114 27.4099L28.3871 33.9424C28.9414 33.4593 29.7216 33.1784 30.2411 32.6513C31.5888 31.2956 32.7988 29.8121 33.8387 28.2038L27.2963 21.6998C28.4705 19.5411 29.0704 17.1123 29.0376 14.6501C29.0695 12.7233 28.7115 10.8102 27.9855 9.02788C27.2595 7.24551 26.1807 5.63137 24.8151 4.2842C23.4818 2.90232 21.8829 1.8097 20.1164 1.0733C18.3499 0.336908 16.4529 -0.0277531 14.5418 0.00166677C12.6274 -0.0279165 10.7272 0.336582 8.95708 1.0729C7.18701 1.80922 5.58427 2.9019 4.24676 4.2842C2.87949 5.63121 1.79813 7.24484 1.06849 9.02689C0.338837 10.8089 -0.0238463 12.7222 0.00250489 14.6501ZM7.16332 7.22707C8.11781 6.2366 9.26255 5.45306 10.5275 4.9244C11.7924 4.39573 13.151 4.13305 14.52 4.15243C15.8858 4.13295 17.2412 4.39567 18.5026 4.92443C19.764 5.4532 20.9049 6.23681 21.855 7.22707C22.8349 8.18683 23.6106 9.33818 24.1345 10.6107C24.6584 11.8833 24.9196 13.2503 24.9021 14.6282V14.6501C24.9237 16.3965 24.5042 18.1198 23.6833 19.6574C23.1892 20.5261 22.5815 21.3239 21.8767 22.0292C21.2564 22.6915 20.5454 23.2609 19.7655 23.7203C18.1849 24.6633 16.3778 25.1495 14.5418 25.1258C13.1708 25.1455 11.8103 24.8808 10.5449 24.3481C9.27938 23.8155 8.13583 23.0262 7.18509 22.0292C6.20201 21.0765 5.42407 19.9295 4.8998 18.6599C4.37553 17.3903 4.11618 16.0253 4.13793 14.6501V14.6282C4.1176 13.2519 4.37537 11.8858 4.89552 10.6133C5.41568 9.34089 6.1873 8.1887 7.16332 7.22707Z" fill="white"/>
          <ellipse cx="31.0623" cy="31.0231" rx="3.93777" ry="3.97623" fill="#31BAFF"/>
          <ellipse cx="31.0623" cy="31.0231" rx="3.93777" ry="3.97623" fill="white"/>
          <path d="M20.2598 19.6055H17.7098L16.9958 17.4635H12.2528L11.5388 19.6055H9.00576L13.1708 7.70547H16.0778L20.2598 19.6055ZM14.6328 10.4085L13.0008 15.2705H16.2648L14.6328 10.4085Z" fill="#31BAFF"/>
          <path d="M20.2598 19.6055H17.7098L16.9958 17.4635H12.2528L11.5388 19.6055H9.00576L13.1708 7.70547H16.0778L20.2598 19.6055ZM14.6328 10.4085L13.0008 15.2705H16.2648L14.6328 10.4085Z" fill="white"/>
        </svg>
        <svg v-if="tip.id === 28" width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35.178 14.0055C35.3678 13.709 35.4919 13.3746 35.5419 13.0252C35.5341 12.7613 35.4497 12.5057 35.2993 12.2899C31.1341 4.52842 25.3917 0.606846 18.0723 0.525146C10.7124 0.615016 4.94979 4.49574 0.784571 12.1673C0.642226 12.4318 0.559374 12.7247 0.541931 13.0252C0.591923 13.3746 0.716076 13.709 0.905886 14.0055C4.7476 21.5627 10.4697 25.4026 18.0723 25.5251C25.5939 25.4026 31.2958 21.5627 35.178 14.0055ZM24.0775 7.26535C24.858 8.02657 25.476 8.9412 25.8933 9.95287C26.3106 10.9645 26.5183 12.0518 26.5038 13.1477C26.5179 14.2617 26.3104 15.3673 25.8937 16.3988C25.4769 17.4303 24.8593 18.3667 24.0775 19.1526C23.3142 19.9452 22.3985 20.5715 21.3865 20.993C20.3746 21.4145 19.288 21.6223 18.1936 21.6036H17.8296C16.7352 21.6223 15.6486 21.4145 14.6367 20.993C13.6247 20.5715 12.709 19.9452 11.9457 19.1526C11.1639 18.3667 10.5463 17.4303 10.1296 16.3988C9.71276 15.3673 9.50528 14.2617 9.5194 13.1477C9.50487 12.0518 9.71265 10.9645 10.1299 9.95287C10.5472 8.9412 11.1652 8.02657 11.9457 7.26535C12.6992 6.45576 13.6118 5.81411 14.625 5.38149C15.6382 4.94887 16.7298 4.73483 17.8296 4.75309H18.1936C19.2934 4.73483 20.385 4.94887 21.3982 5.38149C22.4114 5.81411 23.324 6.45576 24.0775 7.26535ZM20.9839 10.0227C20.6154 9.61348 20.1656 9.28736 19.6639 9.06563C19.1622 8.84391 18.6199 8.73157 18.0723 8.73594C17.5065 8.7295 16.9454 8.84037 16.4237 9.0617C15.9021 9.28303 15.4309 9.6101 15.0393 10.0227C14.6441 10.4124 14.3325 10.88 14.1238 11.3966C13.9151 11.9131 13.8138 12.4674 13.8262 13.0252C13.8262 14.1627 14.2735 15.2537 15.0698 16.0581C15.8661 16.8625 16.9461 17.3144 18.0723 17.3144C18.6199 17.3187 19.1622 17.2064 19.6639 16.9847C20.1656 16.7629 20.6154 16.4368 20.9839 16.0276C21.3947 15.6565 21.7253 15.2038 21.955 14.6977C22.1846 14.1916 22.3083 13.6431 22.3184 13.0864C22.3246 12.5182 22.2084 11.9554 21.978 11.4369C21.7476 10.9185 21.4084 10.4569 20.9839 10.084" fill="white"/>
        </svg>
        <svg v-if="tip.id === 29" width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3121_7893)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2995 31.5691V29.3713C12.2995 28.4721 12.8995 28.1724 13.8995 28.1724H23.1995C24.1995 28.1724 24.7995 28.572 24.7995 29.3713V31.5691C24.4995 34.4663 22.3995 35.9648 18.4995 35.9648C14.6995 35.8649 12.5995 34.4663 12.2995 31.5691ZM10.9995 2.59746C13.1995 0.999022 15.7995 0.0999022 18.4995 0C21.1995 0 23.7995 0.89912 25.9995 2.49756C27.3995 3.59648 28.5995 4.89521 29.4995 6.49365C30.2995 8.09208 30.7995 9.79042 30.8995 11.5887C31.0995 13.3869 30.7995 15.285 30.1995 16.9834C29.5995 18.6817 28.4995 20.2802 27.1995 21.5789C26.3995 22.3781 25.8995 23.3771 25.7995 24.4761C25.6995 25.575 25.2995 26.2743 24.4995 26.2743H12.7995C11.9995 26.2743 11.5995 25.6749 11.4995 24.4761C11.2995 23.3771 10.7995 22.3781 10.0995 21.5789C8.79953 20.2802 7.69953 18.7816 7.09953 16.9834C6.39953 15.285 6.19953 13.3869 6.29953 11.5887C6.39953 9.79042 6.79953 8.09208 7.69953 6.49365C8.39953 4.99511 9.59953 3.59648 10.9995 2.59746Z" fill="white"/>
          <path d="M18.4995 8.09204H20.3995V19.2811H18.1995V10.3898L16.0995 10.9892L15.4995 9.19097L18.4995 8.09204Z" fill="#31BAFF"/>
          </g>
          <defs>
          <clipPath id="clip0_3121_7893">
          <rect width="36" height="35.9648" fill="white" transform="translate(0.5)"/>
          </clipPath>
          </defs>
        </svg>
        <svg v-if="tip.id === 30" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
          <path d="M20.5 37C29.6127 37 37 29.6127 37 20.5C37 11.3873 29.6127 4 20.5 4C11.3873 4 4 11.3873 4 20.5C4 29.6127 11.3873 37 20.5 37Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18 14L24.8333 20.8333L18 27.6667" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-if="tip.id === 31" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5004 5.91504C11.102 5.91504 5.91504 11.102 5.91504 17.5004C5.91504 23.8988 11.102 29.0858 17.5004 29.0858C22.1379 29.0858 26.1441 26.361 27.9958 22.4142C28.5822 21.1642 30.071 20.6263 31.3209 21.2127C32.5709 21.7992 33.1088 23.2879 32.5224 24.5379C29.878 30.1742 24.1485 34.0858 17.5004 34.0858C8.34056 34.0858 0.915039 26.6602 0.915039 17.5004C0.915039 8.34056 8.34056 0.915039 17.5004 0.915039C22.6516 0.915039 27.2558 3.2664 30.2938 6.94505C31.173 8.00964 31.0227 9.5854 29.9582 10.4646C28.8936 11.3438 27.3178 11.1935 26.4386 10.1289C24.3097 7.5511 21.0972 5.91504 17.5004 5.91504Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5908 1.17107C32.9715 1.17107 34.0908 2.29036 34.0908 3.67107V11.9207C34.0908 13.3014 32.9715 14.4207 31.5908 14.4207H23.3412C21.9605 14.4207 20.8412 13.3014 20.8412 11.9207C20.8412 10.5399 21.9605 9.42066 23.3412 9.42066H29.0908L29.0908 3.67107C29.0908 2.29036 30.2101 1.17107 31.5908 1.17107Z" fill="white"/>
          </svg>
      </span>
      </button>
      <div :class="['rebus-tip-price', {'rebus-tip-price--hide' : tip.hidePrice}]">
        <div class="tip-price-value">{{tip.diamonds}}</div>
      </div>
     </div>
  </div>

  <Modal
      v-model="showModal"
      @close="confirmClose"
      @confirm="confirmClose"
      class="modal-wrapper tip-modal"
  >
    <template #customHeader>
      <div class="current-tip-title">Уверены, что хотите использовать подсказку "{{activeTip.title}}"?</div>
    </template>
    <div class="current-tip-text">
      С вас будет списано
     <div class="current-tip-price">{{activeTip.diamonds}} кристаллов</div>
    </div>

    <template #customFooter>
      <div class="modal-buttons-large">
        <div class="btn btn-secondary" @click="confirmClose"><span class="btn--text">Нет</span></div>
        <div class="btn btn-primary" @click="tipActivate" :class="{disabled: false}"><span class="btn--text">Да</span></div>
      </div>
    </template>
  </Modal>

  <Modal
      v-model="balanceModal"
      :title="'Вам не хватает кристаллов!'"
      @close="balanceModal = false"
      class="modal-wrapper tip-modal"
  >
  <div class="current-tip-text">
      Хотите ли Вы купить
     <div class="current-tip-price">{{activeTip.diamonds}} кристаллов</div>
  </div>

    <template #customFooter>
      <div class="modal-buttons-large mt-0">
        <div class="btn btn-secondary" @click="balanceModal = false"><span class="btn--text">Закрыть</span></div>
        <div class="btn btn-primary" @click="goToStore"><span class="btn--text">Купить</span></div>
      </div>
    </template>

  </Modal>

</div>

</template>


<script>
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'difficulty',
  props: {
    tips: {
      type: Object,
      default: {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    cancelBtn: {
      type: Boolean,
      default: false
    },
    additionalClass: {
      type: String,
      default: '',
    }
  },
  emits: ['buy', 'countDownEnd'],
  components: {
    Modal,
    VueCountdown
  },
  data() {
    return {
      showModal: false,
      activeTip: {},
      balanceModal: false,
      gameContinue: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
  },
  created() {

  },
  methods: {
    ...mapActions(useUserStore, ['rebusGame', 'checkGame', 'getUserInfo', 'setBackUrl']),
    tipModal(tip) {
      if (!tip.disabled && !this.loading) {
      if (this.userInfo.diamonds >= tip.diamonds) {
        this.activeTip = tip;
        this.showModal = true;
      }
      else {
        this.balanceModal = true
      }
    }
    },
    modalClose() {
      this.showModal = false;
    },
    goToStore() {
      this.setBackUrl(this.$route.path);
      this.$router.push({name: 'shop'});
    },
    confirmClose() {
      this.showModal = false;
    },
    async tipActivate(){
      this.$emit('buy',this.activeTip);
      this.showModal = false;
    },
  }
};
</script>
