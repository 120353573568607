<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.shop.pageTitle") }}</div>
    <div class="page-withScoll">
      <div  v-if="game" class="page-inner-wrap">
        <div class="shop-header">
          <router-link class="btn btn-primary btn-medium btn-small btn-back" :to="{name: this.game}">
            <span class="btn--text">
              <span>
                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 0.999756L2.34853 4.15123C1.8799 4.61986 1.8799 5.37965 2.34853 5.84828L5.5 8.99976" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
                Вернуться в игру
            </span>
          </span>
          </router-link>
        </div>
      </div>
      <div :class="['scroll-container', {'scroll-container-withHeader' : !!game}]">

        <div class="shop-list-section">
          <shopItem v-for="item in shopListMoney" :item="item"></shopItem>
        </div>

        <div class="shop-list-section">
          <shopItem v-for="item in shopListPoint" :item="item" :star="true"></shopItem>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import shopItem from '@/components/shop/shopItem.vue';
import { useUserStore } from '@/store/user';

export default {
  name: 'ShopView',
  components: {
    shopItem,
  },
  data(){
    return{
      storeList: [],
      game: '',
    }
  },
  async created() {
    this.storeList = await this.getShop();
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'shop', 'storeBackUrl']),
    shopListMoney() {
      if(this.shop.data){
        return this.shop.data[Object.keys(this.shop.data)[0]];
      }
    },
    shopListPoint() {
      if(this.shop.data){
        return this.shop.data[Object.keys(this.shop.data)[1]];
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, ['getShop']),
  },
  mounted() {
    if (this.$route.meta.game) {
      this.game = this.$route.meta.game.substring(2);
    }
},
};
</script>
