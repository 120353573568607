<template>
  <div class="shop-item" v-if="item" :class="{ 'shop-item-star' : star, 'shop-item-rub' : !star, 'disabled': !isActive }">
    <div class="shop-item-caption">{{ item.diamonds }}</div>
    <div :class="['btn btn-primary btn-medium btn-shop', {'disabled' : !isActive}]" @click="modalOpen"><span :class="['btn--text', {'btn-medium-text' : star}]">Купить за&nbsp;<span class="shop-item-price">{{ item.price }}</span><span v-if="!star">&nbsp;руб</span></span></div>

    <Modal
        v-model="showModal"
        :title="$t('pages.shop.popups.buy.title')"
        :btnText="$t('pages.shop.popups.buy.btnText')"
        :buyType="star ? 'star' : 'cash'"
        :btnClass="btnClass"
        @close="modalClose"
        @confirm="modalConfirm"
    >
      <div v-if="star" class="buy-popup-text" v-html='$t("pages.shop.popups.buy.text_star", {price: price})'></div>
      <div v-else class="buy-popup-text" v-html='$t("pages.shop.popups.buy.text", {phone: userPhone, price: price})'></div>
      <div class="buy-popup-reward">
        <div class="buy-popup-reward-title">{{ $t('pages.shop.popups.buy.crystals', item.diamonds) }}</div>
        <div class="buy-popup-reward-img">
          <img src="@/assets/img/crystall-popup.svg" alt="" />
        </div>
      </div>
    </Modal>

    <Modal
        v-model="confirmProcessModal"
        :title="'Покупка кристаллов'"
        :btnText="'Отлично!'"
        @close="confirmProcessClose"
        @confirm="confirmProcessClose"
    >
      <div class="buy-popup-text" v-if="!star">
        Идёт оформление покупки<br/>
        <span>{{ item.diamonds }} кристаллов</span> за <strong>{{ item.price }} рублей</strong> в игре «Разгадайка».
        В&nbsp;течение пяти минут зачислятся кристаллы. Если возникнут проблемы&nbsp;&mdash; мы&nbsp;вам сообщим
      </div>
      <div class="buy-popup-text" v-else>
        Идёт оформление покупки<br/>
        <span>{{ item.diamonds }} кристаллов</span> за <strong class="star">{{ item.price }}</strong> в игре «Разгадайка».
        В&nbsp;течение пяти минут зачислятся кристаллы. Если возникнут проблемы&nbsp;&mdash; мы&nbsp;вам сообщим
        <br><br>
      </div>
      <div class="buy-popup-reward">
        <div class="buy-popup-rewardShop-img">
          <img v-if="darkTheme" src="@/assets/img/success-dark.svg" alt="" />
          <img v-else src="@/assets/img/success-light.svg" alt="" />
        </div>
      </div>
    </Modal>

    <Modal
        v-model="confirmSuccessModal"
        :title="'Покупка кристаллов'"
        :btnText="'Отлично!'"
        @close="confirmSuccessClose"
        @confirm="confirmSuccessClose"
    >
      <div class="buy-popup-text" v-if="!star">
        Теперь даже самый сложный уровень – не проблема:<br/><br/>
        Вы купили <span> {{ item.diamonds }} кристаллов</span> за <strong>{{ item.price }} рублей</strong> в игре «Разгадайка».
      </div>
      <div class="buy-popup-text" v-else>
        Теперь даже самый сложный уровень – не проблема:<br/><br/>
        Вы купили <span> {{ item.diamonds }} кристаллов</span> за <strong class="star">{{ item.price }}</strong> в игре «Разгадайка».
        <br><br>
      </div>
      <div class="buy-popup-reward">
        <div class="buy-popup-rewardShop-img">
          <img v-if="darkTheme" src="@/assets/img/success-dark.svg" alt="" />
          <img v-else src="@/assets/img/success-light.svg" alt="" />
        </div>
      </div>
    </Modal>


    <Modal
        v-model="errorModal"
        :title="'Ошибка'"
        :btnText="'Закрыть'"
        @close="errorModalClose"
        @confirm="errorModalClose"
    >
      <div class="buy-popup-text">
        На вашем лицевом счёте {{phoneNumber}} недостаточно средств. Хотите пополнить?
      </div>
      <div class="buy-popup-reward">
        <div class="popup-img popup-img-error">
          <img v-if="darkTheme" src="@/assets/img/errorpay-dark.svg" alt="" />
          <img v-else src="@/assets/img/errorpay-light.svg" alt="" />
        </div>
      </div>
    </Modal>

  </div>
</template>


<script>
import { useUserStore } from '@/store/user';
import { useMainStore } from '@/store';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'

export default {
  name: 'ShopItem',
  props: {
    item: {
      type: Object,
      default: {}
    },
    star: {
      type: Boolean,
      default: false
    }

  },
  components: {
    Modal
  },
  data() {
    return {
      showModal: false,
      confirmSuccessModal: false,
      confirmProcessModal: false,
      paymentSuccess: false,
      errorModal: false,
      buyInProcess: false,
      backUrl: ''
    };
  },
  computed: {
    ...mapState(useMainStore, ['darkTheme']),
    ...mapState(useUserStore, ['userInfo', 'storeBackUrl']),
    btnClass() {
     return this.buyInProcess ? 'disabled' : '' ;
    },
    userPhone(){
      this.phone = `<strong>${this.phoneNumber}</strong>`;
      return this.phone;
    },
    phoneNumber () {
      if (this.userInfo.msisdn) {
        const msisdn = this.userInfo.msisdn + '';
        return `+${msisdn.substring(0,1)} (${msisdn.substring(1,4)}) ${msisdn.substring(4,7)}-${msisdn.substring(7,9)}-${msisdn.substring(9,11)}`;
      }
      return '';
    },
    isActive(){
      this.isActiveItem = parseInt(this.userInfo.points ) < parseInt( this.item.price) ? false : true;
      this.isActiveItem = this.star ? this.isActiveItem : true;
      return this.isActiveItem;
    }
  },
  created() {

    this.price = `<strong>${this.item.price} рублей</strong>`;
    if (this.star){
      this.price = `<strong class="star">${this.item.price}</strong>`;
    }
    if (this.storeBackUrl != ''){
      this.backUrl = this.storeBackUrl;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['buyDiamods', 'checkProgress' ,'setBackUrl', 'openSuspendModal']),
    errorModalClose() {
      this.errorModal = false;
    },
    modalClose() {
      this.showModal = false;
    },
    modalOpen() {
      if (this.userInfo.is_subscribed == 0)
        window.location.href = ('/subscribe_web');
      else if (this.userInfo.is_suspended == 1) {
        this.openSuspendModal();
      }
      else
        this.showModal = true;
    },
    confirmSuccessClose() {
      this.confirmSuccessModal = false;
      this.goBack();
    },
    confirmProcessClose() {
      this.confirmProcessModal = false;
      this.goBack();
    },
    goBack() {
      this.confirmSuccessModal = false;
      if (this.backUrl!=''){
        this.setBackUrl('');
        this.$router.push(this.backUrl)
      }
    },
    async modalConfirm() {
      if (this.buyInProcess) {
        return
      }

      this.buyInProcess = true;

      let res = await this.buyDiamods({
        "sell": this.item.price,
        "diamonds": this.item.diamonds,
        "gameType": "rebus",
        "type": this.star ? 1 : 2
      })


      this.showModal = false;
      this.buyInProcess = false;
      if (res.message) {
        this.errorModal = true;
      } else {
          if (res != 'undefined'){
            if (res.data.isSuccess){
              this.confirmSuccessModal = true;
            }
            if(res.data.inProgress){
              this.checkProgress({'id': res.data.id});
              this.confirmProcessModal = true;
            }
          }
      }
      this.buyInProcess = false;
    },
  }
};
</script>
