{
  "title": "Text",
  "description": "Description",
  "policy": "Data processing policy",
  "terms": "Terms of use",
  "langTitle": "English",
  "nav": {
    "menu": {
      "home": "Home",
      "shop": "Shop",
      "promo": "Promo",
      "profile": "Profile",
      "lang": "Lang"
    },
    "back": "Go Back"
  },
  "pages": {
    "main": {
      "pageTitle": "Home"
    },
    "langs": {
      "pageTitle": "Lang"
    },
    "shop": {
      "pageTitle": "Store",
      "popups": {
        "buy": {
          "title": "Buying crystals",
          "text": "{price} rubles will be debited from your personal account {phone} and credited",
          "crystals": "'0 Кристаллов | {n} Кристалл | {n} Кристаллов | {n} Кристаллов',",
          "btnText": "Buy"
        }
      }
    }
  }
}
